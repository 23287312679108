import { getDeviceSerialnumberStatisticsWithGraph } from "@src/api/requests"
import { ATTRIBUTE, PERIOD } from "@src/api/types"
import { localization } from "@src/config"
import { colors } from "@src/constants"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { useUser } from "@src/user"
import { sToH } from "@src/utils"
import { convertToYYYYMMDD, parseYYYYMMDD } from "@src/utils/datetime"
import { BarElement, CategoryScale, Chart as ChartJS, ChartOptions, LinearScale, Tooltip } from "chart.js"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Card, Dropdown, DropdownButton, Form, Spinner } from "react-bootstrap"
import { Bar } from "react-chartjs-2"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useSearchParams } from "react-router-dom"

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip)

const periodLabels = ["week", "month", "year"]

const atrributeToUnit = {
  distance: "km",
  riding_time: "h",
  avg_speed: "km/h",
  co2saving: "kg",
}
const attributeLabels = Object.keys(atrributeToUnit)

const floorValue = (value: number) => Math.floor(value)

const calcMatrix = {
  distance: {
    week: floorValue,
    month: floorValue,
    year: floorValue,
  },
  riding_time: {
    week: sToH,
    month: sToH,
    year: sToH,
  },
  avg_speed: {
    week: floorValue,
    month: floorValue,
    year: floorValue,
  },
  co2saving: {
    week: floorValue,
    month: floorValue,
    year: floorValue,
  },
}

type StatisticsTabProps = {
  serialnumber: string
}

export const StatisticsTab: React.FC<StatisticsTabProps> = ({ serialnumber }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useLocalization("device_screen")
  const { brand, partner } = usePartner()
  const [chartData, setChartData] = useState<{ labels: string[]; values: string[]; units: string[] }>({
    labels: [],
    values: [],
    units: [],
  })
  const [avgStatisticsValue, setAvgStatisticsValue] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const { language, formatDate, region } = useLocalization()
  const [chartLabels, setChartLabels] = useState<string[]>([])
  const [showToast, setShowToast] = useState<boolean>(false)
  const [toastText, setToastText] = useState<string>("")
  const [selectedDate, setSelectedDate] = useState<Date>(new Date())
  const [changeDate, setChangeDate] = useState<boolean>(false)
  const { user, distanceConversion } = useUser()

  const tab = searchParams.get("tab")
  const period = searchParams.get("period") as PERIOD
  const attribute = searchParams.get("attribute") as ATTRIBUTE
  const date = searchParams.get("date")

  const styles = {
    datepickerDiv: {
      borderWidth: 0.8,
      borderStyle: "solid",
      borderColor: "#dee2e6",
      borderRadius: "4px",
    },
    warningCard: {
      backgroundColor: colors.whiteGray,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      alignSelf: "center",
    },
  }
  useEffect(() => {
    console.log("search params", searchParams)
  }, [searchParams])

  const updateSearchParams = (param: string, value: string) => {
    searchParams.set(param, value)
    setSearchParams(searchParams, { replace: true })
  }

  useEffect(() => {
    if (tab == "statistics") {
      attribute && period && date && updateStatisticGraph()
      !attributeLabels.includes(attribute) && updateSearchParams("attribute", "distance")
      !periodLabels.includes(period) && updateSearchParams("period", "week")
      const todaysDate = new Date().toISOString().split("T")[0]
      !date && updateSearchParams("date", formatDate(new Date(todaysDate)).replace(/\./g, "-"))
    }
  }, [tab, attribute, period])

  useEffect(() => {
    if (changeDate) {
      updateStatisticGraph()
    }
  }, [changeDate])

  const updateStatisticGraph = async () => {
    try {
      setLoading(true)
      setChangeDate(false)

      const convertedDate = convertToYYYYMMDD(selectedDate)

      if (!partner?.uuid) {
        throw new Error("Partner UUID is undefined")
      }

      const { response, data } = await getDeviceSerialnumberStatisticsWithGraph(partner.uuid, serialnumber, {
        period,
        start_date: convertedDate,
        attribute,
      })

      if (!response.ok) return

      const labels: string[] = []
      const values: string[] = []
      const units: string[] = []

      data?.statistics?.graph_data.forEach((item) => {
        const rawValue = Number(Object.values(item)[0])
        const label = Object.keys(item)[0]
        const unit =
          attribute === "distance"
            ? user?.system_of_measurement === "metric"
              ? "km"
              : "mi"
            : data.statistics[`${attribute}_unit`]

        labels.push(label)
        units.push(unit)

        const calculatedValue =
          attribute === "distance"
            ? distanceConversion(rawValue).split(" ")[0]
            : calcMatrix[attribute][period](rawValue)

        values.push(String(calculatedValue))
      })

      const metaDate = data?.meta?.start_date.split(" ")[0] || ""
      const convertedMetaDate = parseYYYYMMDD(metaDate)

      if (data && convertedDate !== data.meta.start_date) {
        setToastText(t("statistic_tab.data_till_date", { value: data.meta.start_date }))
        setShowToast(true)
      } else {
        setShowToast(false)
      }

      if (selectedDate !== convertedMetaDate) {
        setSelectedDate(convertedMetaDate)
      }

      setChartLabels(labels)
      setChartData({ labels, values, units })

      const avgValue = data?.statistics ? data.statistics[attribute] : 0
      const avgFormatted =
        attribute === "distance"
          ? distanceConversion(avgValue)
          : attribute === "riding_time"
            ? `${calcMatrix[attribute][period](avgValue)}h`
            : `${calcMatrix[attribute][period](avgValue)} ${data?.statistics?.[`${attribute}_unit`] || ""}`

      setAvgStatisticsValue(avgFormatted)
    } catch (error) {
      console.error("Failed to update statistic graph:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (period !== "month") {
      const labels: string[] = []
      const values = chartData.values
      const units = chartData.units
      chartLabels.map((item) => {
        labels.push(t(`statistic_tab.${item as keyof typeof t}`))
      })
      setChartData({ labels, values, units })
    }
  }, [language, chartLabels])

  const data = {
    type: "bar",
    labels: chartData.labels,
    datasets: [
      {
        label: chartData.units[0],
        data: chartData.values,
        backgroundColor: brand.color,
        borderWidth: 0,
        borderRadius: 25,
        borderColor: colors.white,
        innerWidth: 12,
        outerWidth: 10,
        barThickness: 10,
        fill: false,
        minBarLength: 2,
      },
    ],
  }

  const options: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const onChangeAttribute = (newAttribute?: string) => {
    if (newAttribute) {
      updateSearchParams("attribute", newAttribute)
    }
  }

  const onChangePeriod = (newPeriod?: string) => {
    if (newPeriod) {
      updateSearchParams("period", newPeriod)
    }
  }

  const onChangeDate = (selectedDate: Date | undefined) => {
    if (selectedDate) {
      setSelectedDate(selectedDate)
      const dateFormatted = formatDate(selectedDate).replace(/\./g, "-")
      updateSearchParams("date", dateFormatted)
      setChangeDate(true)
    }
  }

  const ChartJS = useCallback(() => <Bar options={options} data={data} width={"100%"} height={50} />, [chartData])
  const avgText = useMemo(
    () => attribute && t(`statistic_tab.${attribute}_sentence`, { value: avgStatisticsValue }),
    [attribute, avgStatisticsValue, language],
  )

  // if (!partner?.permissions?.can_see_device_statistics) return <PermissionCard />

  if (!(tab == "statistics" && period && attribute && data)) return null

  return (
    <div>
      <div className="d-flex justify-content-end mb-2 align-items-center">
        {loading && <Spinner size="sm" animation="border" className="me-2" />}
        <DropdownButton
          title={t(`statistic_tab.${attribute}`)}
          variant=""
          onSelect={(eventKey) => {
            eventKey && onChangeAttribute(eventKey)
          }}>
          {attributeLabels.map((attributeLabel, i) => (
            <Dropdown.Item as="label" key={i} eventKey={attributeLabel} active={attributeLabel == attribute}>
              {t(`statistic_tab.${attributeLabel as keyof typeof t}`)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <DropdownButton
          title={t(`statistic_tab.${period}`)}
          className="me-2"
          variant=""
          onSelect={(eventKey) => {
            eventKey && onChangePeriod(eventKey)
          }}>
          {periodLabels.map((periodLabel, idx) => (
            <Dropdown.Item as="label" key={idx} eventKey={periodLabel} active={periodLabel == period}>
              {t(`statistic_tab.${periodLabel as keyof typeof t}`)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        <div style={styles.datepickerDiv}>
          <DatePicker
            wrapperClassName="datePicker"
            selected={selectedDate}
            onChange={(date) => date && onChangeDate(date)}
            dateFormat={localization.localizationFormat[region][3]}
            closeOnScroll
            showIcon
          />
        </div>
      </div>

      <ChartJS />
      <Form.Text muted className="d-flex justify-content-center">
        {avgText}
      </Form.Text>
      {showToast && (
        <div className="d-flex justify-content-center">
          <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
            {toastText}
          </Card>
        </div>
      )}
    </div>
  )
}
