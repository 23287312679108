import { useLocalization } from "@src/localization"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { DetailsTab } from "./DetailsTab"
import { RouteTab } from "./routetab/RouteTab"
import { StatisticsTab } from "./StatisticsTab"

// TODO: string file will be added in the separate ticket task.
const TABS = ["details", "statistics", "route"]

type DeviceTabsProps = {
  serialnumber: string
}

export const DeviceTabs: React.FC<DeviceTabsProps> = ({ serialnumber }) => {
  const { t } = useLocalization("device_screen")
  const [searchParams, setSearchParams] = useSearchParams()
  const [key, setKey] = useState<string>("details")
  const tab = searchParams.get("tab")

  useEffect(() => {
    setKey("details")
    if (tab === null || !TABS.includes(tab)) setSearchParams({ tab: "details" }, { replace: true })
  }, [serialnumber])

  useEffect(() => {
    if (tab) setKey(tab)
  }, [tab])

  return (
    <Tabs
      activeKey={tab || "details"}
      onSelect={(key) => {
        if (key) {
          setKey(key)
          setSearchParams({ tab: key })
        }
      }}
      className="mb-3">
      <Tab eventKey={TABS[0]} title={t("details")}>
        {key == "details" && <DetailsTab serialnumber={serialnumber} />}
      </Tab>
      <Tab eventKey={TABS[1]} title={t("driving_statistics")}>
        <StatisticsTab serialnumber={serialnumber} />
      </Tab>
      <Tab eventKey={TABS[2]} title={t("routes")}>
        {key == "route" && <RouteTab />}
      </Tab>
    </Tabs>
  )
}
