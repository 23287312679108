import "bootstrap/dist/css/bootstrap.min.css"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import { AuthenticationProvider } from "./auth"
import { DeviceProvider } from "./device/useDevice"
import "./index.css"
import { InviteProvider } from "./invite"
import { LocalizationProvider } from "./localization/useLocalization"
import { PartnerProvider } from "./partner"
import reportWebVitals from "./reportWebVitals"
import { FenceProvider, FleetProvider } from "./settings"
import { UserProvider } from "./user"

const rootElement = document.getElementById("root")

if (rootElement) {
  const root = createRoot(rootElement)
  root.render(
    <BrowserRouter>
      <AuthenticationProvider>
        <InviteProvider>
          <PartnerProvider>
            <DeviceProvider>
              <LocalizationProvider>
                <UserProvider>
                  <FleetProvider>
                    <FenceProvider>
                      <App />
                    </FenceProvider>
                  </FleetProvider>
                </UserProvider>
              </LocalizationProvider>
            </DeviceProvider>
          </PartnerProvider>
        </InviteProvider>
      </AuthenticationProvider>
    </BrowserRouter>,
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
