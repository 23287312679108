import { callApi } from "../../callApi"
import { GET_DEVICE_RESPONSE } from "../getDevice"

type ApiParams = {
  partnerUuid: string
  fleetUuid?: string
  endpointType: string
}

const buildPath = ({ partnerUuid, fleetUuid, endpointType }: ApiParams): string => {
  if (fleetUuid !== undefined && fleetUuid !== "") {
    return `/partner/${partnerUuid}/fleets/${fleetUuid}/statistics/${endpointType}`
  }
  return `/partner/${partnerUuid}/statistics/${endpointType}`
}

const queryString = (params: GET_PARTNER_STATISTICS_PARAMS) => {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined) 
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) 
    .join("&");
}

export type GET_STATISTICS_COUNT_RESPONSE = {
  count: number
}

export type GET_DISTANCE_RESPONSE = {
  avg_distance: number
  avg_distance_unit: string
  total_distance: number
  total_distance_unit: string
}

export type GET_CO2_RESPONSE = {
  avg_co2: number
  avg_co2_unit: string
  total_co2: number
  total_co2_unit: string
}

export type GET_TRIP_RESPONSE = {
  avg_trips: number
  total_trips: number
}

export type GET_SPEED_RESPONSE = {
  avg_speed: number
  avg_speed_unit: string
  total_speed: number
  total_speed_unit: string
}

export type GET_PARTNER_STATISTICS_PARAMS = {
  partner_id: string
  page: number
  per_page: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
  months?: number
  fleetUuid?: string
}

// new fleet statistics
export const getDevicesCount = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_count" }),
  })

export const getDevicesWithMovementLasthour = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_movement_last_hour" }),
  })

export const getDevicesWithTripCount = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_trip_count" }),
  })

export const getDevicesWithMovementCount = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_movement_count" }),
  })

export const getDevicesWithValidPositionCount = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_valid_position_count" }),
  })

export const getDevicesWithValidPositionLastDay = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_valid_position_last_day" }),
  })

export const getDevicesWithNoServicebooking = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_no_servicebooking" }),
  })

export const getDevicesWithExpiringServicebooking = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_expiring_servicebooking" }),
  })

export const getDevicesWithCriticalBattery = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "devices_with_critical_battery" }),
  })

export const getLockedDevices = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "locked_devices" }),
  })

export const getUnlockedDevices = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "unlocked_devices" }),
  })

export const getTheftsReported = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_STATISTICS_COUNT_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "thefts_reported" }),
  })

export const getDistance = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_DISTANCE_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "distance" }),
  })

export const getCo2 = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_CO2_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "co2" }),
  })

export const getTrips = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_TRIP_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "trips" }),
  })

export const getSpeed = async (partnerUuid: string, fleetUuid: string) =>
  callApi<null, GET_SPEED_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: "speed" }),
  })

export const getExpiredServiceBooking = async (
  partnerUuid: string,
  fleetUuid: string,
  params: GET_PARTNER_STATISTICS_PARAMS,
) => {
  return callApi<null, GET_DEVICE_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: `expired_servicebooking?${queryString(params)}` }),
  })
}

export const getLowBatteryDevices = async (
  partnerUuid: string,
  fleetUuid: string,
  params: GET_PARTNER_STATISTICS_PARAMS,
) => {
  return callApi<null, GET_DEVICE_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: `low_battery?${queryString(params)}` }),
  })
}

export const getMissingServicebookingDevices = async (
  partnerUuid: string,
  fleetUuid: string,
  params: GET_PARTNER_STATISTICS_PARAMS,
) => {
  return callApi<null, GET_DEVICE_RESPONSE>({
    method: "GET",
    path: buildPath({ partnerUuid, fleetUuid, endpointType: `missing_servicebooking?${queryString(params)}` }),
  })
}
