import { map } from "@src/config"
import { useLocalization } from "@src/localization"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { AsyncTypeahead } from "react-bootstrap-typeahead"

export type FENCEPOST = {
  lat: number
  lng: number
}

type SearchCityFenceProps = {
  onSearchResult: (coordinates: FENCEPOST[]) => void
}

type SearchCityFenceType = {
  addresstype: string
  boundingbox: [string, string, string, string]
  class: string
  display_name: string
  geojson: {
    type: string
    coordinates: any[][]
  }
  importance: number
  lat: string
  licence: string
  lon: string
  name: string
  osm_id: number
  osm_type: string
  place_id: number
  place_rank: number
  type: string
}

export const SearchCityFence = forwardRef(({ onSearchResult }: SearchCityFenceProps, ref) => {
  const { t } = useLocalization("settings.fence")
  const [options, setOptions] = useState<SearchCityFenceType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const typeaheadRef = useRef<any>(null)

  useImperativeHandle(ref, () => ({
    clearSearch: () => {
      if (typeaheadRef.current) {
        typeaheadRef.current.clear()
      }
    },
  }))

  const handleSearch = (query: string) => {
    setIsLoading(true)
    if (!query) {
      return
    }

    fetch(`${map.openStreetQueryUrl}${query}`)
      .then((resp) => resp.json())
      .then((data) => {
        const filterGeoJsonType = data.filter(function (data: SearchCityFenceType) {
          return data.geojson.type === "MultiPolygon" || data.geojson.type === "Polygon"
        })
        setOptions(filterGeoJsonType)
      })
      .finally(() => setIsLoading(false))
  }

  const renderCoordinate = (paths: any[]) => {
    const coordinates: FENCEPOST[] = []
    let position = 0
    paths.map((location) => {
      if (position % 30 === 0) {
        coordinates.push({ lat: location[1], lng: location[0] })
      }
      position++
      return true
    })
    onSearchResult(coordinates)
  }

  const handleChange = (selectedOptions: any[]) => {
    selectedOptions.forEach((option) => {
      if (option.geojson.type === "MultiPolygon") {
        renderCoordinate(option.geojson.coordinates[0][0])
      } else if (option.geojson.type === "Polygon") {
        renderCoordinate(option.geojson.coordinates[0])
      } else {
        alert("option.geojson.type: MultiPolygon & Polygon")
      }
    })
  }

  const renderDiv = (options: any) => {
    return <div>{options.display_name}</div>
  }

  return (
    <AsyncTypeahead
      className="mt-2 flex-row"
      ref={typeaheadRef}
      id="type_ahead"
      isLoading={false}
      options={options}
      labelKey="display_name"
      delay={500}
      onSearch={handleSearch}
      onChange={handleChange}
      placeholder={t("search_city")}
      renderMenuItemChildren={(option) => {
        return isLoading ? <div>Loading...</div> : renderDiv(option)
      }}></AsyncTypeahead>
  )
})
