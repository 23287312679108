import { useLocalization } from "@src/localization"
import { DevicesSelectTab } from "@src/settings/components"
import React, { useEffect } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { FleetContactScreen } from "./FleetContactScreen"
import { FleetNotificationScreen } from "./FleetNotificationScreen"

const TABS = ["devices", "fleetContact", "fleetNotification"]

export const FleetsTabs: React.FC = () => {
  const { t } = useLocalization("settings.fleets")
  const { t: sidebarT } = useLocalization("sidebar")
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")

  useEffect(() => {
    if (tab === null || !TABS.includes(tab)) setSearchParams({ tab: "devices" }, { replace: true })
  }, [])

  return (
    <Tabs activeKey={tab || "devices"} onSelect={(key) => setSearchParams({ tab: key || "devices" })} className="mb-3">
      <Tab eventKey={TABS[0]} title={sidebarT("devices")}>
        <DevicesSelectTab type={"Fleet"} />
      </Tab>
      <Tab eventKey={TABS[1]} title={t("fleetContact")}>
        <FleetContactScreen />
      </Tab>
      <Tab eventKey={TABS[2]} title={t("fleetNotification")}>
        <FleetNotificationScreen />
      </Tab>
    </Tabs>
  )
}
