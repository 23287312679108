import { USER } from "@src/user"
import { callApi } from "../callApi"

export type POST_USER = {
  user: Omit<USER, "id">
}

export const postUser = async (user: POST_USER) =>
  callApi<POST_USER, null>({
    method: "POST",
    path: "/users",
    body: user,
  })
