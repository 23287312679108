import de from "./de.json"
import en from "./en.json"
import es from "./es.json"
import fr from "./fr.json"
import it from "./it.json"

export default {
  de,
  en,
  fr,
  es,
  it,
};
