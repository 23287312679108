import React, { useEffect, useState } from "react"

// export interface Storage {
//   load: (key: string) => Promise<unknown | null>
//   save: (key: string, value: unknown) => Promise<boolean>
//   remove: (key: string) => Promise<void>
//   clear: () => Promise<void>
// }

/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export const load = <T>(key: string): T | null => {
  try {
    const almostThere = localStorage.getItem(key)
    if (!almostThere) {
      return null
    }
    return JSON.parse(almostThere)
  } catch {
    return null
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
const save = (key: string, value: unknown): boolean => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch {
    return false
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key: string): void {
  try {
    localStorage.removeItem(key)
  } catch {
    /* continue regardless of error */
  }
}

/**
 * Burn it all to the ground.
 */
export function clear(): void {
  try {
    localStorage.clear()
  } catch {
    /* continue regardless of error */
  }
}

/**
 * Persist a state value in the storage
 * It is important that you call useRehydrate before usePersist.
 * Otherwise the default state will persist itself before the persisted state could be rehydrated.
 */
export const usePersist = <T>(key: string, value: T, rehydrationFinished: boolean): void => {
  useEffect(() => {
    if (rehydrationFinished) save(key, value)
  }, [value, rehydrationFinished])
}

/**
 * Rehydrate storage value to state
 */
export const useRehydrate = (key: string, updateFn: (n: any) => void): boolean => {
  const [finished, setFinished] = useState<boolean>(false)
  useEffect(() => {
    const rehydrate = async () => {
      const value = load(key)
      if (value !== undefined && value !== null) updateFn(value)
      setFinished(true)
    }
    rehydrate()
  }, [])

  return finished
}
export function usePersistedState<T>(key: string, defaultState: T): [T, (state: T) => void, boolean] {
  const [state, setState] = React.useState<T>(defaultState)
  const finished = useRehydrate(key, setState)
  usePersist(key, state, finished)

  return [state, setState, finished]
}
