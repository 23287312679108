import { colors } from "@src/constants"
import { PERMISSIONS, usePartner } from "@src/partner"
import { FLEETS, useFleets } from "@src/settings"
import { StyleSheet } from "@src/types"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { FleetDropDown } from "../FleetDropDown"
import { PermissionCard } from "../PermissionCard"
import { SideMenu } from "./components"

type PageProps = {
  fullscreen?: boolean
  isSettings?: boolean
  showFleet?: boolean
  showFleetWithoutAll?: boolean
  showHeader?: boolean
  headerValue?: string
  onSelectFleet?: (fleet: FLEETS | undefined) => void
  children?: React.ReactNode
}

const styles: StyleSheet = {
  headerText: {
    fontSize: 22,
  },
  divider: {
    backgroundColor: colors.gray,
    height: 1,
    width: "100%",
  },
  relative: { position: "relative" },
  warningCard: { backgroundColor: colors.whiteGray },
}

export const Page: React.FC<PageProps> = ({
  fullscreen,
  children,
  isSettings,
  showFleet,
  showFleetWithoutAll,
  showHeader,
  headerValue,
  onSelectFleet,
}) => {
  const shadow = "shadow bg-white rounded "
  const { permissions, partner, changePartner } = usePartner()
  const { partnerUuid, fleetUuid } = useParams()
  const { updateFleetUuid } = useFleets()
  const [permissionSetting, setPermissionSetting] = useState<PERMISSIONS>()

  useEffect(() => {
    if (fleetUuid) updateFleetUuid(fleetUuid)
    if (partner && partnerUuid && partner?.uuid !== partnerUuid) {
      changePartner(partnerUuid)
    }

    if (permissions) {
      setPermissionSetting(permissions)
    }
  }, [partnerUuid, fleetUuid, partner, permissions])

  return (
    <Container>
      {permissionSetting != undefined && !permissionSetting?.viewDevices && !fullscreen ? (
        <PermissionCard />
      ) : (
        <Row>
          {!fullscreen && (
            <Col xs={12} md={4} lg={3}>
              <SideMenu isSettings={isSettings} />
            </Col>
          )}
          <Col
            xs={12}
            md={fullscreen ? 12 : 8}
            lg={fullscreen ? 12 : 9}
            style={styles.relative}
            className={`${!fullscreen ? shadow + "mt-4" : "mt-4"} mb-4 p-4`}>
            <div className="m-1">
              {showHeader && (
                <>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="large" style={styles.headerText}>
                      {headerValue}
                    </label>
                    {showFleet && (
                      <FleetDropDown
                        withoutAllOption={showFleetWithoutAll}
                        onClick={(fleet) => onSelectFleet && onSelectFleet(fleet)}
                      />
                    )}
                  </div>
                  <div className="mt-2 mb-2" style={styles.divider}></div>
                </>
              )}
              {children}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  )
}
