import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Button } from "react-bootstrap"

interface MultiSelectProps {
  options: { id: number; label: string }[]
  selectedId: number
  onChange?: (selectedOptions: number[]) => void
  className?: string
  selectedColor?: string
  unselectedColor?: string
}

interface MultiSelectRef {
  resetSelection: () => void
}

const MultiSelect = forwardRef<MultiSelectRef, MultiSelectProps>(
  (
    { options, selectedId, onChange, className = "", selectedColor = "bg-blue-500", unselectedColor = "bg-gray-200" },
    ref,
  ) => {
    const [selectedOptions, setSelectedOptions] = useState<number[]>([])

    useEffect(() => {
      if (selectedId) {
        toggleOption(selectedId)
      }
    }, [selectedId])

    useImperativeHandle(ref, () => ({
      resetSelection: () => {
        setSelectedOptions([])
        onChange?.([])
      },
    }))

    const toggleOption = (optionId: number) => {
      const newSelectedOptions = selectedOptions.includes(optionId)
        ? selectedOptions.filter((id) => id !== optionId)
        : [...selectedOptions, optionId]

      setSelectedOptions(newSelectedOptions)
      onChange?.(newSelectedOptions)
    }

    return (
      <div className={`flex items-center space-x-2 ${className}`}>
        {options.map((option) => (
          <Button
            key={option.id}
            style={{ height: 28, display: "inline-flex", alignItems: "center" }}
            onClick={() => toggleOption(option.id)}
            className={`
              px-4 py-2 m-1 mt-2
              rounded-full
              text-sm
              transition-colors
              duration-200
              ${
                selectedOptions.includes(option.id)
                  ? `${selectedColor} btn-primary`
                  : `${unselectedColor} btn-primary-outline`
              }
            `}>
            {option.label}
          </Button>
        ))}
      </div>
    )
  },
)

MultiSelect.displayName = "MultiSelect"

export default MultiSelect
