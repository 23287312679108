import { InputField } from "@src/components"
import { colors } from "@src/constants"
import { useDevice } from "@src/device/useDevice"
import { DEVICE_TYPE, TCAN_DATA } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { useUser } from "@src/user"
import { sToH } from "@src/utils"
import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import { BoxArrowUpRight, InfoCircle } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { defaultCenter, Map, polygonColors } from "./components"

type DetailsTabProps = {
  serialnumber: string
}

const styles = {
  verticalDivider: { backgroundColor: colors.gray, flex: 1, height: 0.8 },
  warningText: {
    backgroundColor: colors.red,
    borderRadius: 5,
    color: colors.white,
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
    paddingLeft: 15,
  },
  datapointsText: { borderRadius: 5, color: colors.red, padding: 2 },
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ serialnumber }) => {
  const { t } = useLocalization("device_screen")
  const { t: dashboardscreenT } = useLocalization("dashboard_screen")
  const { t: devicesT } = useLocalization("device_screen")
  const { t: settingsT } = useLocalization("settings.partner.book_service")
  const { formatDateTime, formatOdometer } = useLocalization()
  const { permissions, partner } = usePartner()
  const [loading, setLoading] = useState<string>("")
  const { patchDevice, device, loadLastTrips, trips } = useDevice()
  const [deviceData, setDeviceData] = useState<DEVICE_TYPE>()
  const serviceEndDate = deviceData?.servicebooking?.service_end_date
    ? formatDateTime(new Date(deviceData.servicebooking.service_end_date))
    : "-"
  const lastPositionTimestamp = deviceData?.last_position_timestamp
    ? formatDateTime(new Date(deviceData?.last_position_timestamp))
    : t("details_tab.no_gps_found")
  const lastSeenTimestamp = deviceData?.last_seen_timestamp
    ? formatDateTime(new Date(deviceData.last_seen_timestamp))
    : "-"
  const googleMapsLink =
    deviceData?.position && !deviceData?.position.error
      ? `https://www.google.com/maps/search/?api=1&query=${deviceData.position.lat},${deviceData.position.lng}`
      : ""
  const [totalDistance, setTotalDistance] = useState<number>(0)
  const [totalDuration, setTotalDuration] = useState<number>(0)
  const { distanceConversion } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (device) setDeviceData(device)
    loadLastTrips(serialnumber)
  }, [device])

  useEffect(() => {
    calculateTotalDistanceAndDuration()
  }, [trips])

  const onInputPersist = (field: string) => async (text: string) => {
    setLoading(field)
    if (!deviceData) return
    const newDevice = { ...deviceData, [field]: text }
    const statusOk = await patchDevice(newDevice)
    statusOk && setLoading("")
  }

  const chooseRandomPolygonColors = (index: number) => {
    return { backgroundColor: polygonColors[index] }
  }

  const calculateTotalDistanceAndDuration = () => {
    let totalKm = 0
    let totalTime = 0
    trips.map((trip) => {
      totalKm += trip.distance
      totalTime += trip.duration_s
    })
    setTotalDistance(totalKm)
    setTotalDuration(totalTime)
  }

  const canData = deviceData?.latest_vehicle_insight

  const findMostRecentUpdateDate = (data: TCAN_DATA): Date => {
    // Filter properties ending with '_at'
    const timestampEntries = Object.entries(data)
      .filter(([key, value]) => {
        return key.endsWith("_at") && key !== "total_trips_distance_at" && value !== null && value !== undefined
      })
      .map(([_, value]) => new Date(value as string))

    // Sort timestamps in descending order
    const sortedEntries = timestampEntries.sort((a, b) => a.getTime() - b.getTime())

    // Return the most recent timestamp and its property name
    return sortedEntries[0]
  }

  const handleLastUpdateCANDataInfoRendering = () => {
    if (!canData) return <></>

    const mostRecentUpdateDate = findMostRecentUpdateDate(canData)
    const timeDifference = (new Date().getTime() - mostRecentUpdateDate.getTime()) / 1000 / 60

    if (timeDifference < 15) return <></>

    return (
      <div className="d-flex align-items-start gap-1 mt-3">
        <InfoCircle color="#636E72" size={24} />
        <i>{t("timestamp_note", { date: formatDateTime(mostRecentUpdateDate) })}</i>
      </div>
    )
  }

  return (
    <Row className="d-flex flex-column flex-lg-row bg-highlight">
      <Col>
        {deviceData?.servicebooking ? (
          <div>
            <Map
              position={(!deviceData?.position?.error && deviceData?.position) || defaultCenter}
              serialnumber={serialnumber}
              showRoute={false}
              fences={deviceData?.fences}
            />
            <Row className="mt-1  flex-lg-column">
              <Col className="d-flex" style={{ flex: 1 }}>
                <Row style={{ flex: 1 }}>
                  <b className="small"> {t("details_tab.last_seen")}:</b>
                  <label className="mt-1">{lastSeenTimestamp || "-"}</label>
                </Row>
                <Row style={{ flex: 1 }}>
                  <b className="small"> {t("details_tab.last_position")}:</b>
                  <div className="d-flex flex-row align-items-center">
                    <label className="mt-1">{lastPositionTimestamp}</label>
                    <a className="ms-2 mb-1" href={`${googleMapsLink}`} target="_blank">
                      <BoxArrowUpRight />
                    </a>
                  </div>
                </Row>
                <Row style={{ flex: 1 }}>
                  <b className="small"> {devicesT("service_booking")}:</b>
                  <label className="mt-1">{serviceEndDate || "-"}</label>
                </Row>
              </Col>
              <Col className="d-flex" style={{ flex: 1 }}>
                <Row style={{ flex: 1 }}>
                  <b className="small mt-2"> {t("route_tab.total_duration")}:</b>
                  <label className="mt-1">{sToH(totalDuration)} h</label>
                </Row>
                {!(canData && canData.odometer !== null) && (
                  <Row style={{ flex: 1 }}>
                    <b className="small mt-2"> {dashboardscreenT("total_distance")}:</b>
                    <label className="mt-1">{distanceConversion(totalDistance)}</label>
                  </Row>
                )}
              </Col>
            </Row>
            {canData && (
              <Row>
                <div style={{ marginTop: 2, marginBottom: 2 }}>
                  <hr />
                </div>
                <Col className="d-flex" style={{ flex: 1 }}>
                  {canData.battery_state_of_charge !== null && (
                    <Row style={{ flex: 1 }}>
                      <b className="small"> {t("bike_state_of_charge")}:</b>
                      <label className="mt-1">
                        {`${canData.battery_state_of_charge} ${canData.battery_state_of_charge_unit}`}
                      </label>
                    </Row>
                  )}
                  {canData.battery_state_of_health !== null && (
                    <Row style={{ flex: 1 }}>
                      <b className="small"> {t("bike_battery_state_of_health")}:</b>
                      <label className="mt-1">
                        {`${canData.battery_state_of_health} ${canData.battery_state_of_health_unit}`}
                      </label>
                    </Row>
                  )}
                  {canData.odometer !== null && (
                    <Row style={{ flex: 1 }}>
                      <b className="small"> {t("bike_odometer")}:</b>
                      <label className="mt-1">
                        {formatOdometer({ odometer: canData.odometer, odometer_unit: canData.odometer_unit })}
                      </label>
                    </Row>
                  )}
                </Col>
                {canData.remaining_distance !== null && (
                  <Row style={{ flex: 1 }}>
                    <b className="small"> {t("remaining_range")}:</b>
                    <label className="mt-1">{`${canData.remaining_distance} ${canData.remaining_distance_unit}${canData.support_level_meaning !== null ? ` - ${canData.support_level_meaning}` : ""}`}</label>
                  </Row>
                )}
                {handleLastUpdateCANDataInfoRendering()}
              </Row>
            )}

            {deviceData?.fences.length !== 0 && (
              <div className="d-flex flex-column mt-2 mb-2">
                <b className="small">{t("details_tab.fence")}:</b>
                <div>
                  {deviceData?.fences?.map((item, i) => {
                    return (
                      <Button
                        className="m-1 btn btn-primary-outline d-inline-flex justify-content-center align-items-center"
                        disabled
                        onClick={() => {
                          //TODO: this will be enabled once this PR is merged https://bitbucket.org/iotventure/iot-dashboard/pull-requests/112
                          //navigate("")
                        }}>
                        {item.name} <div className="colored-circle" style={chooseRandomPolygonColors(i)} />
                      </Button>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            <div>
              <label className="ms-1 mt-2" style={styles.datapointsText}>
                <b>{t("details_tab.datapoints_warn")}</b>
              </label>
            </div>
            <div className="d-flex flex-column mb-1">
              <b className="small"> {devicesT("service_booking")}</b>
              <div>
                <label className="ms-1 mt-2" style={styles.warningText}>
                  {t("details_tab.no_service").toUpperCase()}
                </label>
                <label className="ms-1 mt-2">
                  {t("details_tab.no_service_msg")}
                  <Button
                    style={{ height: "38px" }}
                    onClick={() => {
                      navigate(`/${partner?.uuid}/settings/partner?tab=book_tracker`, {
                        state: { serialnumber },
                      })
                    }}>
                    {settingsT("extend_service")}
                  </Button>
                  {/* <a
                    href={`mailto:IoT-Venture Support<${mailgun.support_email}>?subject=${t(
                      "details_tab.support_subject",
                    )}&body=${t("details_tab.support_content")} ${deviceData?.serialnumber}`}>
                    {t("details_tab.support")}
                  </a> */}
                </label>
              </div>
            </div>
          </>
        )}
      </Col>
      <Col>
        {/* <InputField
          label={t("details_tab.commissioned")}
          value={deviceData?.commissioning && formatDateTime(new Date(deviceData.commissioning))}
          onPersist={onInputPersist("commissioned")}
          loading={loading == "commissioned"}
          isReadOnly={true}
        />

        <div className="mt-2 mb-3" style={styles.verticalDivider} /> */}
        {/* <InputField
          label={t("details_tab.description")}
          value={deviceData?.description}
          onPersist={onInputPersist("description")}
          loading={loading == "description"}
          isReadOnly={!permissions?.editDevices}
        /> */}
        <InputField
          name={"field-1"}
          label={t("details_tab.framenumber")}
          value={deviceData?.framenumber || ""}
          onPersist={onInputPersist("framenumber")}
          loading={loading == "framenumber"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-2"}
          label={t("details_tab.framecolor")}
          value={deviceData?.framecolor || ""}
          onPersist={onInputPersist("framecolor")}
          loading={loading == "framecolor"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-3"}
          label={t("details_tab.deviceoptions")}
          value={deviceData?.bikeoptions || ""}
          onPersist={onInputPersist("bikeoptions")}
          loading={loading == "bikeoptions"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-4"}
          label={t("details_tab.brand")}
          value={deviceData?.brand || ""}
          onPersist={onInputPersist("brand")}
          loading={loading == "brand"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-5"}
          label={t("details_tab.devicemodel")}
          value={deviceData?.bikemodel || ""}
          onPersist={onInputPersist("bikemodel")}
          loading={loading == "bikemodel"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-6"}
          label={t("details_tab.owner_name")}
          value={deviceData?.owner_name || ""}
          onPersist={onInputPersist("owner_name")}
          loading={loading == "owner_name"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-7"}
          label={t("details_tab.owner_address")}
          value={deviceData?.owner_address || ""}
          onPersist={onInputPersist("owner_address")}
          loading={loading == "owner_address"}
          isReadOnly={!permissions?.editDevices}
        />
        <InputField
          name={"field-8"}
          label={t("details_tab.owner_phone")}
          value={deviceData?.owner_phone || ""}
          onPersist={onInputPersist("owner_phone")}
          loading={loading == "owner_phone"}
          isReadOnly={!permissions?.editDevices}
        />
      </Col>
    </Row>
  )
}
