import { putPartnerUuid } from "@src/api"
import { cloudinary } from "@src/config"
import { useLocalization } from "@src/localization"
import { PARTNER, usePartner } from "@src/partner"
import React, { useEffect, useRef, useState } from "react"
import { Button, Row, Spinner } from "react-bootstrap"
import { ColorPicker } from "../components"

// Define props type
type PartnerDetailProps = {
  isCreatePartner?: boolean
  onColorChange?: (color: string) => void
  onLogoChange?: (logoUrl: string) => void
}

const defaultPartner = {
  name: "",
  logo_url: "",
  color: "#000000",
} satisfies Omit<NonNullable<PARTNER>, "uuid" | "description" | "contact" | "permissions">

export const PartnerDetail: React.FC<PartnerDetailProps> = ({ isCreatePartner, onColorChange, onLogoChange }) => {
  type PartnerDataType = Omit<NonNullable<PARTNER>, "uuid" | "description" | "contact" | "permissions"> | PARTNER
  const { partner, updatePartners } = usePartner()
  const [partnerData, setPartnerData] = useState<PartnerDataType>(defaultPartner)

  const [colorLoading, setColorLoading] = useState<boolean>(false)
  const [image, setImage] = useState<any>()
  const inputRef = useRef<HTMLInputElement>(null)
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const { t } = useLocalization("settings.partner")
  const imgBorderRadius = { borderRadius: 10 }

  useEffect(() => {
    if (partner) {
      setPartnerData(partner)
    }
  }, [partner])

  const handleUpload = () => {
    inputRef.current?.click()
  }

  const uploadImages = async (imageData: File) => {
    if (imageData) {
      setImageLoading(true)
      const data = new FormData()
      data.append("file", imageData)
      data.append("upload_preset", cloudinary.upload_preset)
      data.append("cloud_name", cloudinary.name)

      try {
        const response = await fetch(`${cloudinary.uploadEndPoint + cloudinary.name}/image/upload`, {
          method: "post",
          body: data,
        })

        const resData = await response.json()
        if (resData) {
          onLogoChange?.(resData.url)
          isCreatePartner && setPartnerData({ ...partnerData, logo_url: resData.url })
          if (partnerData) {
            const updatedPartnerData = { ...partnerData, logo_url: resData.url } as PARTNER
            updatePartnerDetails(updatedPartnerData)
          }
        }
      } catch (error) {
        console.error("Image upload failed:", error)
      } finally {
        setImageLoading(false)
      }
    }
  }

  const updatePartnerDetails = (updatedPartnerData: PARTNER) => {
    putPartnerUuid(updatedPartnerData.uuid, { partner: updatedPartnerData })
      .then(({ response }) => {
        if (response.ok) {
          updatePartners()
          setPartnerData(updatedPartnerData)
          setColorLoading(false)
        }
      })
      .catch((error) => {
        console.error("Failed to update partner details:", error)
      })
      .finally(() => {
        setColorLoading(false)
      })
  }

  const handleColorChange = (color: string) => {
    setColorLoading(true)
    onColorChange?.(color)
    isCreatePartner && setPartnerData({ ...partnerData, color: color })
    if (partnerData) {
      const updatedPartnerData = { ...partnerData, color: color } as PARTNER
      updatePartnerDetails(updatedPartnerData)
    }
  }

  return (
    <>
      <Row>
        <div className="d-flex">
          <b className="small">{t("brandColor")}</b>
        </div>
        <ColorPicker
          onChange={handleColorChange}
          loading={colorLoading}
          color={
            partnerData && partnerData.color && !partnerData.color.includes("#")
              ? `#${partnerData.color}`
              : partnerData?.color || "#000000"
          }
        />
      </Row>
      <Row>
        <div className="mt-2 ">
          <b className="small">{t("logo")}</b>
          <Row>
            <div>
              {imageLoading ? (
                <Spinner className="ms-3 mt-3" animation="border" size="sm" />
              ) : (
                partnerData?.logo_url && (
                  <img
                    className="mt-2 me-3"
                    src={partnerData?.logo_url}
                    height="70"
                    style={imgBorderRadius}
                    alt="Partner Logo"
                  />
                )
              )}
              <input
                ref={inputRef}
                className="d-none"
                type="file"
                onChange={(e) => {
                  const selectedFile = e.target.files?.[0]
                  if (selectedFile) {
                    setImage(selectedFile)
                    uploadImages(selectedFile)
                  }
                }}
              />
              <Button size="sm" onClick={handleUpload} className="mt-2 btn btn-primary-outline">
                {t("browse")}
              </Button>
            </div>
          </Row>
          <Row>
            <label className="mt-2">{image && image.name}</label>
          </Row>
        </div>
      </Row>
      {!partner && !isCreatePartner && (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      )}
    </>
  )
}
