import { callApi } from "@src/api/callApi"
import { TCAN_DATA } from "@src/devices/types"

type GET_LATEST_VEHICLE_INSIGHTS_RESPONSE = {
  success: boolean
  latest_vehicle_insight: TCAN_DATA
}

export const getLatestVehicleInsights = (partnerUuid: string, serialnumber: string) =>
  callApi<null, GET_LATEST_VEHICLE_INSIGHTS_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/device/${serialnumber}/latest_vehicle_insight`,
  })
