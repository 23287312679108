import { InputField, Page } from "@src/components"
import { useLocalization } from "@src/localization"
import React, { useEffect, useState } from "react"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { FenceDetailTab } from "./tabs/FenceDetailTab"
import { useFence } from "./useFence"

const TABS = ["fence", "device"]

export const EditFenceScreen: React.FC = () => {
  const { t } = useLocalization("settings.fence")
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab") || "fence"
  const [loadingField, setLoadingField] = useState<string>("")
  const { fence, patchFence } = useFence()
  const [key, setKey] = useState<string>("fence")

  useEffect(() => {
    if (tab === null || !TABS.includes(tab)) setSearchParams({ tab: "fence" }, { replace: true })
  }, [])

  const onInputPersist = (field: string) => async (text: string) => {
    setLoadingField(field)
    if (fence) {
      const body = { fence: { kind: "classic", fencingposts: JSON.stringify(fence.fencingposts), name: text } }
      const response = await patchFence(body)
      if (response.ok) setLoadingField("")
    }
  }

  return (
    <Page isSettings={true}>
      <div>
        <Row>
          <Col xs={12} md={6} lg={4}>
            <InputField value={fence?.name ?? ""} onPersist={onInputPersist("name")} loading={loadingField == "name"} />
          </Col>
        </Row>
        <Tabs
          activeKey={tab ?? "fence"}
          onSelect={(key) => {
            if (key) {
              setKey(key)
              setSearchParams({ tab: key })
            }
          }}
          className="mb-3">
          <Tab eventKey={TABS[0]} title={t("tabs.fence")}>
            {key == "fence" && <FenceDetailTab />}
          </Tab>
        </Tabs>
      </div>
    </Page>
  )
}
