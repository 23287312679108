import { InfoWindow, Marker } from "@react-google-maps/api"
import { LockUnlockIcon } from "@src/components"
import { map } from "@src/config"
import { colors } from "@src/constants"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { COORDINATES_TYPE } from "../Map"

type PopupMarkerProps = {
  datapoint: COORDINATES_TYPE
  device: SLIM_DEVICE_TYPE
  clusterer?: any
}

const styles = {
  verticalDivider: { backgroundColor: colors.gray, width: 1.5, height: 20 },
  divStyle: {
    background: "white",
  },
  infoWindowOptions: { disableAutoPan: true },
}

export const PopupMarker: React.FC<PopupMarkerProps> = ({ datapoint, device, clusterer }) => {
  const { brand, partner } = usePartner()
  const { t } = useLocalization("device_screen")
  const { durationFromDate } = useLocalization()
  const navigate = useNavigate()
  const [open, setOpen] = useState<boolean>(false)

  const markerIconSVG = (markerColor: string) => {
    return Object.assign({
      path: map.markerSVGPath,
      fillColor: markerColor,
      fillOpacity: 0.85,
      strokeColor: colors.white,
      strokeWeight: 1,
      scale: 2.5,
      anchor: new window.google.maps.Point(4, 15),
    })
  }
  const onLoadMarker = (marker: google.maps.Marker) => {
    marker.setIcon(markerIconSVG(brand.color))
  }

  const renderInfoWindow = () => {
    return (
      <InfoWindow
        options={styles.infoWindowOptions}
        onCloseClick={() => setOpen(false)}
        position={{
          lat: Number(datapoint.lat),
          lng: Number(datapoint.lng),
        }}>
        <div style={styles.divStyle} className="d-flex flex-column">
          <label className="fw-bold mt-1">{device.name}</label>
          <div className="d-flex flex-row align-items-center mt-1 pb-1">
            <LockUnlockIcon locked={device?.locked} />
            <div className="ms-1" style={styles.verticalDivider} />
            <label className="ms-1 mt-1">{device.serialnumber}</label>
          </div>
          <label className="mt-1">{device?.fleet?.name || "-"}</label>
          <label className="mt-2">
            {t("last_seen")} {device?.last_seen_timestamp && durationFromDate(device?.last_seen_timestamp)}
          </label>
          <label className="mt-1">
            {t("last_position")} {device?.last_position_timestamp && durationFromDate(device?.last_position_timestamp)}
          </label>

          {device?.reported_theft && (
            <a className="mt-2" href={device.reported_theft}>
              {t("theft_report")}
            </a>
          )}
          <a className="pointer mt-2" onClick={() => navigate(`/${partner?.uuid}/device/${device.serialnumber}`)}>
            {t("show_more")}
          </a>
        </div>
      </InfoWindow>
    )
  }

  const onToggle = () => {
    setOpen(!open)
  }

  return (
    <Marker
      key={device.serialnumber}
      position={{ lat: Number(datapoint.lat), lng: Number(datapoint.lng) }}
      clusterer={clusterer}
      onLoad={(marker) => onLoadMarker(marker)}
      onClick={() => onToggle()}>
      {open && renderInfoWindow()}
    </Marker>
  )
}
