import { ModalView, Page } from "@src/components"
import { colors } from "@src/constants"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import React, { useEffect, useState } from "react"
import { Button, Card, Col, ListGroup, ListGroupItem, Spinner } from "react-bootstrap"
import { TrashFill } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { useFleets } from "../fleets"
import { FENCE } from "./types"
import { useFence } from "./useFence"

export const FenceListScreen: React.FC = () => {
  const { partner } = usePartner()
  const navigate = useNavigate()
  const { t } = useLocalization("settings")
  const { t: sidebarT } = useLocalization("sidebar")
  const { fences, fenceNavPath, deleteFenceById, clearFence } = useFence()
  const [fencesList, setFences] = useState<FENCE[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [selectedFence, setSelectedFence] = useState<FENCE>()
  const [loading, setLoading] = useState<boolean>(false)
  const { fleet, fleets } = useFleets()
  const styles = { warningCard: { backgroundColor: colors.whiteGray } }

  useEffect(() => {
    setLoading(true)
    if (fences) {
      setFences(fences)
      setLoading(false)
    }
  }, [fleet, fences])

  const renderFenceCard = (fenceItem: FENCE) => {
    return (
      <ListGroupItem key={fenceItem.id}>
        <div className="d-flex align-items-center pointer">
          <Col
            onClick={() => {
              navigate(`${fenceNavPath}/${fenceItem.id}`)
            }}>
            <b>{fenceItem.name ?? "-"}</b>
          </Col>
          <Button
            disabled={false}
            className="align-self-end"
            variant="danger"
            onClick={() => {
              setSelectedFence(fenceItem)
              setShowDeleteModal(true)
            }}>
            {selectedFence && selectedFence.id == fenceItem.id && deleteLoader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <TrashFill color={colors.white} />
            )}
          </Button>
        </div>
      </ListGroupItem>
    )
  }

  const onClickDelete = async () => {
    setDeleteLoader(true)
    setShowDeleteModal(false)
    if (selectedFence) {
      const response = await deleteFenceById(selectedFence.id)
      if (response.ok) setDeleteLoader(false)
    }
  }

  return (
    <Page
      isSettings
      showHeader
      showFleet
      showFleetWithoutAll
      headerValue={sidebarT("geofence")}
      onSelectFleet={() => {
        setLoading(true)
      }}>
      {fleets && fleets.length == 0 ? (
        <Card style={styles.warningCard} className="mt-4 p-2">
          {t("fence.validate.no_fleets")}
        </Card>
      ) : (
        <ListGroup>
          <ListGroup.Item
            action
            onClick={() => {
              clearFence()
              navigate(`/${partner?.uuid}/settings/geofences/create`)
            }}>
            <b>{t("fence.new_fence")}</b>
          </ListGroup.Item>
          {loading ? (
            <div className="d-flex justify-content-center mt-2">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {fencesList.length == 0 ? (
                <ListGroup.Item>{t("fence.no_fence")}</ListGroup.Item>
              ) : (
                <>{fencesList.map((fence) => renderFenceCard(fence))}</>
              )}
            </>
          )}
        </ListGroup>
      )}
      <ModalView
        title={t("fleets.alert")}
        buttonTitle={t("fleets.delete")}
        show={showDeleteModal}
        buttonLoader={deleteLoader}
        onHideModal={() => setShowDeleteModal(false)}
        onButtonClick={() => onClickDelete()}>
        <label>{t("fence.delete_fence_msg")}</label>
      </ModalView>
    </Page>
  )
}
