import { callApi } from "../callApi"
import { GET_DEVICE_PARAMS, GET_DEVICE_RESPONSE } from "./getDevice"

export const getFleetsUuidDevices = async (partnerUuid: string, fleetUuid: string, params: GET_DEVICE_PARAMS) => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined) 
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) 
    .join("&");
  return callApi<null, GET_DEVICE_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/devices?${queryString}`,
  })
}
