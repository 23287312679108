import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { callApi } from "../callApi"

export type GET_DEVICE_PARAMS = {
  page?: number
  per_page?: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
}

export type META_PARAMS = {
  page?: number
  per_page?: number
  total?: number
  search_string?: string
  sort_by?: string
  sort_desc?: string
}

export type GET_DEVICE_RESPONSE = {
  devices: SLIM_DEVICE_TYPE[]
  meta: META_PARAMS
}

export const getDevice = (partnerUuid: string, fleetUuid?: string, params: GET_DEVICE_PARAMS = {}) => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined) 
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) 
    .join("&");
  const urlPath = fleetUuid
    ? `/partner/${partnerUuid}/fleets/${fleetUuid}/devices?${queryString}`
    : `/partner/${partnerUuid}/device?${queryString}`
  return callApi<null, GET_DEVICE_RESPONSE>({ method: "GET", path: urlPath })
}
