import { GoogleMap, Marker, Polygon, useJsApiLoader } from "@react-google-maps/api"
import { map as mapObject } from "@src/config"
import { colors } from "@src/constants"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useRef, useState } from "react"

type Coordinate = {
  lat: number
  lng: number
}

type MapProps = {
  center: Coordinate
  zoom: number
  geofence: Coordinate[]
  onGeofenceChange: (geofence: Coordinate[]) => void
  onMapChange: (center: Coordinate, zoom: number) => void
  editable: boolean
}

const mapContainerStyle = {
  width: "100%",
  height: "600px",
}

const options = {
  polygonOptions: {
    fillColor: colors.mustardYellow,
    fillOpacity: 0.35,
    strokeColor: colors.darkViolet,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: true,
    editable: true,
    geodesic: true,
    zIndex: 1,
  },
  mapOptions: {
    disableDefaultUI: true,
    zoomControl: true,
    gestureHandling: "greedy",
  },
}

export const GeoFenceMap: React.FC<MapProps> = ({
  center,
  zoom,
  geofence,
  onGeofenceChange,
  onMapChange,
  editable,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: mapObject.key,
  })

  const [map, setMap] = useState<google.maps.Map | undefined>()
  const polygonRef = useRef<google.maps.Polygon | undefined>()
  const listenersRef = useRef<google.maps.MapsEventListener[]>([])
  const [path, setPath] = useState<Coordinate[]>(geofence)
  const [statecenter, setCenter] = useState<Coordinate>(center)
  const [statezoom, setZoom] = useState<number>(zoom)

  useEffect(() => {
    setCenter(center)
    setZoom(zoom)
  }, [zoom, center])

  const onLoad = useCallback((map: google.maps.Map) => {
    if (map) return
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(undefined)
  }, [])

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const newPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng(),
        }))
      setPath(newPath)
      onGeofenceChange(newPath)
    }
  }, [onGeofenceChange])

  // Debounce the onEdit function to prevent too many updates during drag
  const debouncedOnEdit = useCallback(debounce(onEdit, 100), [onEdit])

  const onLoadPoly = useCallback(
    (polygon: google.maps.Polygon) => {
      polygonRef.current = polygon
      const path = polygon.getPath()

      listenersRef.current.push(
        path.addListener("insert_at", onEdit),
        path.addListener("set_at", onEdit),
        path.addListener("remove_at", onEdit),
      )
    },
    [onEdit, editable],
  )

  const onUnmountPoly = useCallback(() => {
    listenersRef.current.forEach((listener) => {
      google.maps.event.removeListener(listener)
    })
    listenersRef.current = []
  }, [])

  const onMapClick = useCallback(
    (e: google.maps.MapMouseEvent) => {
      if (editable && e.latLng) {
        const newPath = [...path, { lat: e.latLng.lat(), lng: e.latLng.lng() }]
        setPath(newPath)
        onGeofenceChange(newPath)
      }
    },
    [path, editable, onGeofenceChange],
  )

  const onVertexClick = useCallback(
    (index: number, e: google.maps.MapMouseEvent) => {
      if (e.domEvent.type === "dblclick") {
        e.domEvent.preventDefault()
        e.domEvent.stopPropagation()
        if (path.length > 0) {
          const newPath = path.filter((_, i) => i !== index)
          setPath(newPath)
          onGeofenceChange(newPath)
        }
      }
    },
    [path, onGeofenceChange],
  )

  const onMarkerDragEnd = useCallback(
    (index: number, e: google.maps.MapMouseEvent) => {
      const newPath = [...path]
      if (e.latLng) {
        newPath[index] = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      }
      setPath(newPath)
      onGeofenceChange(newPath)
    },
    [path, onGeofenceChange],
  )

  useEffect(() => {
    if (map) {
      map.addListener("center_changed", () => {
        const newCenter = map.getCenter()
        if (newCenter) {
          onMapChange({ lat: newCenter.lat(), lng: newCenter.lng() }, map.getZoom() || zoom)
        }
      })
      map.addListener("zoom_changed", () => {
        const newCenter = map.getCenter()
        if (newCenter) {
          onMapChange({ lat: newCenter.lat(), lng: newCenter.lng() }, map.getZoom() || zoom)
        }
      })
    }
  }, [map, onMapChange, zoom])

  useEffect(() => {
    setPath(geofence)
  }, [geofence])

  const renderMarkers = () => {
    const vertexMarkerIcon = {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 4.5, // Size of the vertex marker
      fillColor: colors.mustardYellow, // Custom vertex color
      fillOpacity: 1,
      strokeWeight: 2,
      strokeColor: colors.darkViolet,
    }
    return (
      <>
        {path.map((vertex, index) => (
          <Marker
            key={index}
            position={vertex}
            icon={vertexMarkerIcon} // Use custom icon for vertices
            draggable={editable}
            onDragEnd={(e) => onMarkerDragEnd(index, e)} // Allow vertices to be dragged
            onDblClick={(e) => onVertexClick(index, e)} // Allow deletion of vertex on double click
          />
        ))}
      </>
    )
  }

  if (!isLoaded) return <div>Loading...</div>

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={statecenter}
      zoom={statezoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options.mapOptions}
      onClick={onMapClick}>
      {path.length > 0 && (
        <>
          {/* Polygon */}
          <Polygon
            paths={path}
            options={{
              ...options.polygonOptions,
              editable: false,
              draggable: editable,
            }}
            onLoad={onLoadPoly}
            onUnmount={onUnmountPoly}
            onDragEnd={debouncedOnEdit} // Use debounced onEdit for drag end
            onMouseUp={debouncedOnEdit} // Debounce for mouse up as well
          />

          {/* Vertex markers */}
          {editable && renderMarkers()}
        </>
      )}
    </GoogleMap>
  )
}
