import { usePartner } from "@src/partner"
import { StyleSheet } from "@src/types"
import React from "react"
import { Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { InfoCircle } from "react-bootstrap-icons"

type StatisticCardProps = {
  show: boolean
  title: string
  loading: boolean
  isDetailed?: boolean
  isDeviceActive?: boolean
  totalCount?: any
  isRadial?: boolean
  isColumnDetail?: boolean
  totalRadialValue?: string
  radialValue?: string
  tooltip: string
  titles?: string[]
  detailCounts?: any[]
  onButtonClick?: () => void
}

const pageClassName = "shadow-2 rounded mt-2 mb-2"
export const alignCenterBetween = "d-flex flex-column align-items-center"

export const StatisticCard: React.FC<StatisticCardProps> = ({ show, title, totalCount, tooltip }) => {
  const { brand } = usePartner()
  const styles: StyleSheet = {
    borderStyles: {
      flex: 1,
      borderWidth: 1,
      borderColor: "#dee2e6",
      borderRadius: "30px",
      height: "inherit",
      maxWidth: "220px",
      minWidth: "170px",
      minHeight: "150px",
    },
    cardTitle: {
      color: "#636E72",
      fontSize: "17px",
      marginTop: "2.5px",
      minHeight: "50px",
    },
    cardValue: {
      color: brand?.color,
    },
    detail: {
      flex: 1,
      flexDirection: "column",
      marginTop: "10px",
      marginRight: "10px",
      padding: "10px",
      backgroundColor: "#F0F0F0",
      justifyContent: "space-between",
      borderRadius: "10px",
    },
    divider: {
      height: "70px",
      backgroundColor: "green",
      width: 2,
    },
    horizontalDivider: {
      backgroundColor: "#F0F0F0",
      width: "100%",
      height: 2,
    },
  }

  const Link: React.FC<{ id: string; children: React.ReactNode }> = ({ id, children }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{tooltip || "No tooltip available"}</Tooltip>}>
      <a href="#" className="ms-1 me-1">
        {children}
      </a>
    </OverlayTrigger>
  )

  if (show) {
    return (
      <Col style={{ display: "flex" }}>
        <Card className={pageClassName} style={styles.borderStyles}>
          <Card.Body>
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <Card.Title style={styles.cardTitle}>{title}</Card.Title>
                <Link id="info">
                  <InfoCircle color="#636E72" size={15} />
                </Link>
              </div>
              {totalCount && (
                <div className="align-self-start me-3">
                  <div className="fs-1" style={styles.cardValue}>
                    {totalCount}
                  </div>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    )
  }
}
