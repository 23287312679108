import { localization } from "@src/config"
import { I18n } from "i18n-js"
import { validateLanguage, validateRegion } from "./helpers"
import translations from "./translations/translations"

const i18n = new I18n()

i18n.translations = translations
i18n.defaultLocale = localization.defaultLang
i18n.enableFallback = true

export const detectBrowserLocales = () => {
  const [language, region] = Intl.DateTimeFormat().resolvedOptions().locale.split("-")

  return [validateLanguage(language), validateRegion(region)]
}

export default i18n
