import { Page } from "@src/components"
import { useLocalization } from "@src/localization"
import React from "react"
import { FenceDetailTab } from "./tabs/FenceDetailTab"

export const CreateGeofenceScreen: React.FC = () => {
  const { t } = useLocalization("settings.fence")

  return (
    <Page isSettings showHeader headerValue={t("new_fence")}>
      <FenceDetailTab />
    </Page>
  )
}
