import { getDevice } from "@src/api"
import {
  getCo2,
  getDevicesCount,
  getDevicesWithCriticalBattery,
  getDevicesWithExpiringServicebooking,
  getDevicesWithMovementCount,
  getDevicesWithMovementLasthour,
  getDevicesWithNoServicebooking,
  getDevicesWithTripCount,
  getDevicesWithValidPositionCount,
  getDevicesWithValidPositionLastDay,
  getDistance,
  getLockedDevices,
  getSpeed,
  getTheftsReported,
  getTrips,
  getUnlockedDevices,
} from "@src/api/requests/statistics"
import { Page } from "@src/components"
import { colors, config } from "@src/constants"
import { centerAlign } from "@src/device/devicedetail/tabs/components"
import { useDevice } from "@src/device/useDevice"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { PARTNER, usePartner } from "@src/partner"
import { FLEETS, useFleets } from "@src/settings"
import { StyleSheet } from "@src/types"
import { useUser } from "@src/user"
import pLimit from "p-limit"
import React, { useEffect, useState } from "react"
import { Col, Row, Spinner } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import { DevicesCard, StatisticCard } from "./components"
import "./Dashboard.css"

type STATISTICS_DATA = {
  devices_count: string
  trip_count: string
  movement_count: string
  movement_lasthour_count: string
  valid_position_count: string
  valid_position_lastday_count: string
  no_servicebooking_count: string
  expiring_servicebooking_count: string
  critical_battery_devices_count: string
  locked_devices_count: string
  unlocked_devices_count: string
  theft_reported_devices_count: string
  avg_distance: string
  total_distance: string
  avg_co2: string
  total_co2: string
  avg_trips: string
  total_trips: string
  avg_speed: string
  total_speed: string
}

const defaultStatisticsData: STATISTICS_DATA = {
  devices_count: "",
  trip_count: "",
  movement_count: "",
  movement_lasthour_count: "",
  valid_position_count: "",
  valid_position_lastday_count: "",
  no_servicebooking_count: "",
  expiring_servicebooking_count: "",
  critical_battery_devices_count: "",
  locked_devices_count: "",
  unlocked_devices_count: "",
  theft_reported_devices_count: "",
  avg_distance: "",
  total_distance: "",
  avg_co2: "",
  total_co2: "",
  avg_trips: "",
  total_trips: "",
  avg_speed: "",
  total_speed: "",
}

const styles: StyleSheet = {
  backgroundDrop: {
    backgroundColor: "rgba(0,0,0,0.3)",
    width: "100%",
    height: "100%",
    zIndex: 1,
    top: 0,
    left: 0,
    bottom: 0,
    borderRadius: ".25rem",
    position: "absolute",
  },
  loaderView: {
    paddingTop: 10,
    paddingLeft: 5,
    paddingRight: 5,
    display: "flex",
    backgroundColor: colors.smokeWhite,
    height: "65px",
    zIndex: 999,
    borderRadius: 8,
    flexDirection: "column",
  },
  borderStyles: {
    flex: 1,
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "14px",
    paddingBottom: "14px",
    borderWidth: 1,
    marginTop: "20px",
    borderColor: "#F0F0F0",
    borderRadius: "10px",
    height: "inherit",
  },
  cardTitle: {
    color: "#636E72",
    fontSize: "17px",
    marginTop: "10px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
}

export const DashboardScreen: React.FC = () => {
  const navigate = useNavigate()
  const { formatDate } = useLocalization()
  const { t } = useLocalization("dashboard_screen")
  const { t: t_sidebar } = useLocalization("sidebar")
  const { partner, permissions } = usePartner()
  const [loading, setLoading] = useState<boolean>(true)
  const [statisticsData, setStatisticsData] = useState<STATISTICS_DATA>(defaultStatisticsData)
  const { fleet } = useFleets()
  const { distanceConversion } = useUser()
  // const [expiredDevices, setExpiredDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [expiringDevices, setExpiringDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [missingServiceBookingDevices, setMissingServiceBookingDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [lowBatteryDevices, setLowBatteryDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [fleetData, setFleetData] = useState<FLEETS>()
  const [partnerData, setPartnerData] = useState<PARTNER>()
  const [deviceLoad, setDeviceLoad] = useState<boolean>(false)
  const { clearDevice } = useDevice()
  const { partnerUuid } = useParams()
  const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000
  const limit = pLimit(16)

  useEffect(() => {
    const abortController = new AbortController()
    return () => abortController.abort() // Abort any ongoing requests when component unmounts
  }, [])

  useEffect(() => {
    setLoading(true)
    if (partner && partner !== partnerData) {
      setPartnerData(partner)
      setLowBatteryDevices([])
      setMissingServiceBookingDevices([])
      setExpiringDevices([])
      setDeviceLoad(true)
      fetchDevices(fleet ? fleet.uuid : undefined)
      getStatistics(fleet ? fleet.uuid : undefined)
      // getDevicesListForStatistics()
    }
  }, [partner, partnerUuid])

  useEffect(() => {
    return () => {
      setPartnerData(undefined)
    }
  })

  useEffect(() => {
    if (fleet && fleet.uuid && fleet !== fleetData) {
      setFleetData(fleet)
      fetchDevices(fleet.uuid)
      getStatistics(fleet.uuid)
    }
  }, [fleet])

  const fetchDevices = async (fleetUUid?: string) => {
    setDeviceLoad(true)
    if (partner?.uuid)
      getDevice(partner?.uuid, fleetUUid || undefined, {
        page: 0,
        per_page: config.itemsPerPage,
      }).then(({ response, data }) => {
        if (response.ok && data) {
          setLowBatteryDevices(data.devices.filter((device) => device.battery < 10))
          setMissingServiceBookingDevices(data.devices.filter((device) => device.servicebooking === null))
          setExpiringDevices(
            data.devices.filter(
              (device) =>
                device.servicebooking &&
                new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime() <=
                  90 * DAY_IN_MILLISECONDS,
            ),
          )
          setLoading(false)
          setDeviceLoad(false)
        }
      })
  }

  // const getDevicesListForStatistics = async () => {
  //   const partnerUuid = partner?.uuid
  //   if (!partnerUuid) {
  //     return
  //   }

  //   try {
  //     // Use Promise.all to make the API calls concurrently
  //     const [expiredDevices, missingServiceBookingDevices, lowBatteryDevices] = await Promise.all([
  //       getExpiredServiceBooking(partnerUuid, fleet?.uuid, {
  //         partner_id: partner?.uuid,
  //         fleetUuid: fleet?.uuid,
  //         page: 0,
  //         per_page: 20,
  //         months: 2,
  //       }),
  //       getMissingServicebookingDevices(partnerUuid, fleet?.uuid, {
  //         partner_id: partner?.uuid,
  //         fleetUuid: fleet?.uuid,
  //         page: 0,
  //         per_page: 20,
  //       }),
  //       getLowBatteryDevices(partnerUuid, fleet?.uuid, {
  //         partner_id: partner?.uuid,
  //         fleetUuid: fleet?.uuid,
  //         page: 0,
  //         per_page: 20,
  //       }),
  //     ])

  //     // Process the API results and update state
  //     // setExpiredDevices(expiredDevices?.data?.devices || [])
  //     // setMissingServiceBookingDevices(missingServiceBookingDevices?.data?.devices || [])
  //     // setLowBatteryDevices(lowBatteryDevices?.data?.devices || [])
  //   } catch (error) {
  //     console.error("Error fetching devices for statistics:", error)
  //   } finally {
  //     // Set loading to false after all operations
  //     // setLoading(false)
  //   }
  // }

  const getStatistics = async (fleetUUid?: string) => {
    setStatisticsData(defaultStatisticsData) // Reset statistics data before fetching

    if (partner?.uuid) {
      const partnerUuid = partner?.uuid

      // Array of functions to execute
      const functions: Array<() => Promise<any>> = [
        () => getDevicesCount(partnerUuid, fleetUUid || ""),
        () => getDevicesWithMovementCount(partnerUuid, fleetUUid || ""),
        () => getDevicesWithMovementLasthour(partnerUuid, fleetUUid || ""),
        () => getDevicesWithTripCount(partnerUuid, fleetUUid || ""),
        () => getDevicesWithValidPositionCount(partnerUuid, fleetUUid || ""),
        () => getDevicesWithValidPositionLastDay(partnerUuid, fleetUUid || ""),
        () => getDevicesWithNoServicebooking(partnerUuid, fleetUUid || ""),
        () => getDevicesWithExpiringServicebooking(partnerUuid, fleetUUid || ""),
        () => getDevicesWithCriticalBattery(partnerUuid, fleetUUid || ""),
        () => getLockedDevices(partnerUuid, fleetUUid || ""),
        () => getUnlockedDevices(partnerUuid, fleetUUid || ""),
        () => getTheftsReported(partnerUuid, fleetUUid || ""),
        () => getDistance(partnerUuid, fleetUUid || ""),
        () => getCo2(partnerUuid, fleetUUid || ""),
        () => getTrips(partnerUuid, fleetUUid || ""),
        () => getSpeed(partnerUuid, fleetUUid || ""),
      ]

      try {
        // Fetch results concurrently while limiting concurrency
        const results = await Promise.all(functions.map((fn) => limit(fn)))

        // Destructure results into meaningful variables
        const [
          devicesCount,
          devicesWithMovementCount,
          devicesWithMovementLastHour,
          devicesWithTripCount,
          devicesWithValidPositionCount,
          devicesWithValidPositionLastDay,
          devicesWithNoServicebooking,
          devicesWithExpiringServicebooking,
          devicesWithCriticalBattery,
          devicesWithLocked,
          devicesWithUnlocked,
          devicesWithTheftReported,
          distance,
          Co2Data,
          tripDetail,
          speed,
        ] = results

        // Construct statistics data object
        const analysisData: STATISTICS_DATA = {
          devices_count: `${devicesCount?.data?.count || 0}`,
          trip_count: `${devicesWithTripCount?.data?.count || 0}`,
          movement_count: `${devicesWithMovementCount?.data?.count || 0}`,
          movement_lasthour_count: `${devicesWithMovementLastHour?.data?.count || 0}`,
          valid_position_count: `${devicesWithValidPositionCount?.data?.count || 0}`,
          valid_position_lastday_count: `${devicesWithValidPositionLastDay?.data?.count || 0}`,
          no_servicebooking_count: `${devicesWithNoServicebooking?.data?.count || 0}`,
          expiring_servicebooking_count: `${devicesWithExpiringServicebooking?.data?.count || 0}`,
          critical_battery_devices_count: `${devicesWithCriticalBattery?.data?.count || 0}`,
          locked_devices_count: `${devicesWithLocked?.data?.count || 0}`,
          unlocked_devices_count: `${devicesWithUnlocked?.data?.count || 0}`,
          theft_reported_devices_count: `${devicesWithTheftReported?.data?.count || 0}`,
          avg_distance: `${distanceConversion(distance?.data?.avg_distance || 0)}`,
          total_distance: `${distanceConversion(distance?.data?.total_distance || 0)}`,
          avg_co2: `${Math.round(Co2Data?.data?.avg_co2 || 0)} ${Co2Data?.data?.avg_co2_unit || ""}`,
          total_co2: `${Math.round(Co2Data?.data?.total_co2 || 0)} ${Co2Data?.data?.total_co2_unit || ""}`,
          avg_trips: `${tripDetail?.data?.avg_trips || 0}`,
          total_trips: `${tripDetail?.data?.total_trips || 0}`,
          avg_speed: `${speed?.data?.avg_speed || 0} ${speed?.data?.avg_speed_unit || ""}`,
          total_speed: `${speed?.data?.total_speed || 0} ${speed?.data?.total_speed_unit || ""}`,
        }

        if (analysisData.devices_count && !fleet) {
          config.itemsPerPage = devicesCount?.data?.count ?? 0
        }

        setStatisticsData(analysisData) // Update state with the new statistics
      } catch (error) {
        console.error("Error fetching statistics:", error)
      }
    }
  }

  const onDeviceClick = (device: SLIM_DEVICE_TYPE) => {
    clearDevice()
    navigate(`/${partner?.uuid}/device/${device.serialnumber}`)
  }

  const renderLoaderSpinner = () => {
    return (
      <>
        <div className={centerAlign} style={styles.backgroundDrop}>
          <div className={centerAlign} style={styles.loaderView}>
            <Spinner className="align-self-center" animation="border" size="sm" />
            <b className="m-2">{t("loader")}</b>
          </div>
        </div>
      </>
    )
  }

  const renderTopRow = () => {
    return (
      <Row>
        <StatisticCard
          show={true}
          title={t("total_trackers")}
          totalCount={statisticsData.devices_count}
          loading={loading}
          isDeviceActive={true}
          tooltip={t("tooltip.total_trackers")}
          onButtonClick={() => navigate(`/${partner?.uuid}/settings/partner?tab=add`)}
        />
        <StatisticCard
          show={true}
          title={t("devices_activated")}
          totalCount={
            statisticsData.devices_count
              ? parseInt(statisticsData.devices_count) - parseInt(statisticsData.no_servicebooking_count)
              : ""
          }
          loading={loading}
          tooltip={t("tooltip.devices_activated")}
        />
        <StatisticCard
          show={true}
          title={t("devices_with_trips")}
          totalCount={statisticsData.trip_count}
          tooltip={t("tooltip.devices_with_trips")}
          loading={loading}
        />

        <StatisticCard
          show={true}
          title={t("reported_thefts")}
          tooltip={t("tooltip.reported_thefts")}
          totalCount={statisticsData.theft_reported_devices_count}
          loading={loading}
        />
      </Row>
    )
  }

  const renderTripsStatistics = () => {
    return (
      <>
        <label style={styles.cardTitle}> {t("trip_statistics")}</label>
        <Row>
          <Col>
            <StatisticCard
              show={true}
              title={t("average_trips")}
              tooltip={t("tooltip.devices_trip_avg")}
              totalCount={statisticsData.avg_trips}
              loading={loading}
            />
            <StatisticCard
              show={true}
              title={t("average_distance")}
              tooltip={t("tooltip.devices_distance_avg")}
              totalCount={statisticsData.avg_distance}
              loading={loading}
            />
          </Col>
          <Col>
            <StatisticCard
              show={true}
              title={t("total_trips")}
              tooltip={t("tooltip.devices_trip_total")}
              totalCount={statisticsData.total_trips}
              loading={loading}
            />
            <StatisticCard
              show={true}
              title={t("total_distance")}
              tooltip={t("tooltip.devices_distance_total")}
              totalCount={statisticsData.total_distance}
              loading={loading}
            />
          </Col>
          <Col>
            <StatisticCard
              show={true}
              title={t("average_co2")}
              tooltip={t("tooltip.co2_savings_avg")}
              totalCount={statisticsData.avg_co2}
              loading={loading}
            />
            <StatisticCard
              show={true}
              title={t("average_speed")}
              tooltip={t("tooltip.devices_speed")}
              totalCount={statisticsData.avg_speed}
              loading={loading}
            />
          </Col>
          <Col>
            <StatisticCard
              show={true}
              title={t("total_co2")}
              tooltip={t("tooltip.co2_savings_total")}
              totalCount={statisticsData.total_co2}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    )
  }

  const renderStateOfTrackers = () => {
    return (
      <>
        <label style={styles.cardTitle}> {t("state_of_trackers")}</label>
        <Row>
          <DevicesCard
            title={t("devices_withouth_servicebooking")}
            devices={missingServiceBookingDevices}
            loading={deviceLoad}
            showTotal={true}
            totalValue={missingServiceBookingDevices.length}
            tooltip={t("tooltip.devices_withouth_servicebooking")}
            onDeviceClick={onDeviceClick}
            onDetailClick={() => {
              if (deviceLoad) return
              if (!permissions?.manageUserRoles) return
              navigate(`/${partner?.uuid}/settings/partner?tab=book_tracker`, {
                state: { devices: missingServiceBookingDevices, id: 1 },
              })
            }}
            formatDeviceTitle={(device) => `${device.serialnumber} - ${device.name}`}
          />
          <DevicesCard
            title={t("devices_with_ending_servicebookings", { value: "90" })}
            devices={expiringDevices}
            loading={deviceLoad}
            showDetail={true}
            showTotal={true}
            totalValue={expiringDevices.length}
            tooltip={t("tooltip.devices_with_ending_service")}
            onDetailClick={() => {
              if (deviceLoad) return
              if (!permissions?.manageUserRoles) return
              navigate(`/${partner?.uuid}/settings/partner?tab=book_tracker`, {
                state: { devices: expiringDevices, id: 2 },
              })
            }}
            onDeviceClick={onDeviceClick}
            formatDeviceTitle={(device) =>
              `${device.name}  ${
                device?.servicebooking ? "- " + formatDate(new Date(device?.servicebooking?.service_end_date)) : ""
              }`
            }
          />
        </Row>
        <Row>
          <Col>
            <DevicesCard
              title={t("devices_with_critical_battery")}
              devices={lowBatteryDevices}
              loading={deviceLoad}
              showTotal={true}
              totalValue={lowBatteryDevices.length}
              tooltip={t("tooltip.critical_battery")}
              onDeviceClick={onDeviceClick}
              formatDeviceTitle={(device) => `${device.name} - ${device.battery} %`}
            />
            <Row>
              <StatisticCard
                show={true}
                isColumnDetail={true}
                title={t("locked_devices")}
                tooltip={t("tooltip.devices_locked")}
                totalCount={statisticsData.locked_devices_count}
                loading={loading}
              />
              <StatisticCard
                show={true}
                isColumnDetail={true}
                title={t("unlocked_devices")}
                tooltip={t("tooltip.devices_unlocked")}
                totalCount={statisticsData.unlocked_devices_count}
                loading={loading}
              />
            </Row>
          </Col>
          <Col>
            <Row>
              <StatisticCard
                show={true}
                title={t("devices_with_movement")}
                tooltip={t("tooltip.devices_with_movement")}
                totalCount={statisticsData.movement_count}
                loading={loading}
              />
              <StatisticCard
                show={true}
                title={t("devices_with_movement_1h")}
                totalCount={statisticsData.movement_count}
                tooltip={t("tooltip.devices_with_movement_1h")}
                loading={loading}
              />
            </Row>
            <Row>
              <StatisticCard
                show={true}
                title={t("devices_with_valid_position_24h")}
                tooltip={t("tooltip.devices_with_valid_position_24h")}
                totalCount={statisticsData.valid_position_lastday_count}
                loading={loading}
              />
              <StatisticCard
                show={true}
                title={t("devices_with_valid_position")}
                tooltip={t("tooltip.devices_with_valid_position")}
                totalCount={statisticsData.valid_position_count}
                loading={loading}
              />
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Page
      showFleet
      showHeader
      headerValue={t_sidebar("statistics")}
      onSelectFleet={(fleet) => {
        setLoading(true)
        setDeviceLoad(true)
        if (!fleet) {
          setLowBatteryDevices([])
          setMissingServiceBookingDevices([])
          setExpiringDevices([])
          setDeviceLoad(true)
          fetchDevices()
          getStatistics()
        }
      }}>
      {loading && renderLoaderSpinner()}
      {renderTopRow()}
      {renderTripsStatistics()}
      {renderStateOfTrackers()}
    </Page>
  )
}
