import { FENCE } from "@src/settings"
import { callApi } from "../callApi"
import { META_PARAMS } from "./getDevice"

type GET_FENCE_RESPONSE = {
  fences: [FENCE]
  meta: META_PARAMS
}

export const getFleetsUuidFences = async (partnerUuid: string, fleetUuid: string) => {
  
  return callApi<null, GET_FENCE_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/fences`,
  })
}
