import { sendEmailViaMailgun } from "@src/api"
import { InputField } from "@src/components"
import { mailgun } from "@src/config"
import { colors, config } from "@src/constants"
import { useLocalization } from "@src/localization"
import { validation } from "@src/utils/validation"
import React, { useState } from "react"
import { Alert, Button, Col, Container, Row, Spinner } from "react-bootstrap"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

type MAIL_CONTENT = {
  firstname: string
  lastname: string
  phonenumber: string
  email: string
  company: string
}

const DEFAULT_MAIL_CONTENT = {
  firstname: "",
  lastname: "",
  phonenumber: "",
  email: "",
  company: "",
}

const styles = {
  divider: {
    backgroundColor: colors.gray,
    height: 1,
    width: "100%",
  },
}

export const AccessRequestScreen: React.FC = () => {
  const { t } = useLocalization("request_access_screen")
  const { t: userformT } = useLocalization("user_form")
  const [content, setContent] = useState<MAIL_CONTENT>(DEFAULT_MAIL_CONTENT)
  const [loading, setLoading] = useState<boolean>(false)
  const [alertShow, setAlertShow] = useState<boolean>(false)

  const alertAnimation = () => {
    setAlertShow(true)
    setTimeout(() => {
      setAlertShow(false)
    }, config.alertSuccessTimeOut)
  }

  const onInputPersist = (field: string) => (text: string) => {
    const contentData = { ...content, [field]: text }
    setContent(contentData)
  }

  const contentText = t("email_content")
  const contentBody = `

${content.firstname}  
${content.lastname}
${content.email}
${content.phonenumber}
${content.company}`

  const enabled =
    content.firstname == "" ||
    content.lastname == "" ||
    !validation.regex.email.test(content && content.email) ||
    !validation.regex.phoneNumber.test(content && content.phonenumber) ||
    content.company == ""

  return (
    <Container>
      <Row className="justify-content-center mb-4">
        <Col md="10" lg="8" className="shadow-lg bg-body p-3" style={COLUMN}>
          <h4 className="large d-flex">{t("request_access")}</h4>
          <div className="mt-2 mb-4" style={styles.divider}></div>
          <InputField
            name={"field-1"}
            label={userformT("firstname")}
            value={content.firstname}
            onChangeText={onInputPersist("firstname")}
            onPersist={onInputPersist("firstname")}
            validate={content.firstname == ""}
            errorMsg={userformT("validation.firstnameValid")}
            loading={false}
            isFocused={true}
            isMandatory={true}
          />
          <InputField
            name={"field-2"}
            label={userformT("lastname")}
            value={content.lastname}
            onPersist={onInputPersist("lastname")}
            onChangeText={onInputPersist("lastname")}
            loading={false}
            validate={content.lastname == ""}
            errorMsg={userformT("validation.lastnameValid")}
            isMandatory={true}
          />
          <InputField
            name={"field-3"}
            label={userformT("email")}
            value={content.email}
            onPersist={onInputPersist("email")}
            onChangeText={onInputPersist("email")}
            loading={false}
            validate={!validation.regex.email.test(content && content.email)}
            errorMsg={userformT("validation.emailValid")}
            isMandatory={true}
          />
          <InputField
            name={"field-4"}
            label={userformT("phonenumber")}
            value={content.phonenumber}
            onPersist={onInputPersist("phonenumber")}
            validate={!validation.regex.phoneNumber.test(content && content.phonenumber)}
            onChangeText={onInputPersist("phonenumber")}
            errorMsg={userformT("validation.phoneNoValid")}
            loading={false}
            isMandatory={true}
          />
          <InputField
            name={"field-5"}
            label={userformT("company")}
            value={content.company}
            onPersist={onInputPersist("company")}
            validate={content.company == ""}
            onChangeText={onInputPersist("company")}
            errorMsg={userformT("validation.companyValid")}
            loading={false}
            isMandatory={true}
          />
          <Button
            type="submit"
            value="submit"
            disabled={enabled}
            className="text-white mt-3"
            onClick={async () => {
              setLoading(true)
              const response = await sendEmailViaMailgun({
                from: `${t("request_access")} - ${content.email}`,
                subject: mailgun.mail_subject,
                text: contentText + contentBody,
              })
              if (response.message) {
                setContent(DEFAULT_MAIL_CONTENT)
                setLoading(false)
                alertAnimation()
              }
            }}>
            {loading ? <Spinner animation="border" size="sm" variant="light" /> : userformT("submit")}
          </Button>
          {alertShow && (
            <Alert className="mt-3" transition={true} variant="success" dismissible onClose={() => setAlertShow(false)}>
              <label>{t("success_message")}</label>
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  )
}
