import { callApi } from "../callApi"

type POST_TOKEN_BODY = {
  email: string
  password: string
}

export type POST_TOKEN_RESPONSE = {
  user_token: {
    access_token: string
    user: {
      id: number
      email: string
      firstname: string
      lastname: string
      phone: string
      address: string
    }
  }
  errors: {
    user?: string
    password?: string
  }
}

export const postToken = async (user: POST_TOKEN_BODY) =>
  callApi<{ user: POST_TOKEN_BODY }, POST_TOKEN_RESPONSE>({
    method: "POST",
    path: "/token",
    body: { user },
  })
