export const validation = {
  regex: {
    email: new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),
    phoneNumber: new RegExp("^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{4}[-s.]?[0-9]{4,6}$"),
    password: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$&()\\-`,\\/])[a-zA-Z0-9!@#$&()\\-`,\\/]{6,128}$"),
  },
}

const isObject = (item: any) => {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export const mergeDeep = (target: { [key: string]: any }, ...sources: { [key: string]: any }[]): { [key: string]: any } => {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        mergeDeep(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return mergeDeep(target, ...sources)
}
