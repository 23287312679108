import { InputField, Page } from "@src/components"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { useLocalization } from "@src/localization"
import { SYSTEM_OF_MEASUREMENT, USER, useUser } from "@src/user"
import React, { useEffect, useState } from "react"
import { Col, Dropdown, DropdownButton, Spinner } from "react-bootstrap"

export const UserSettingsScreen: React.FC = () => {
  const { t } = useLocalization("settings.user")
  const { t: sidebarT } = useLocalization("sidebar")
  const { user, updateUser } = useUser()
  const [loadingField, setLoadingField] = useState<string>("")
  const [userData, setUserData] = useState<USER>(user)
  const [systemOfMeasurement, setSystemOfMeasurement] = useState<SYSTEM_OF_MEASUREMENT>()
  const units: SYSTEM_OF_MEASUREMENT[] = ["imperial", "metric"]
  const [loading, setLoading] = useState<boolean>(false)

  const onInputPersist = (field: keyof NonNullable<USER>) => (text: string) => {
    if (!userData?.id) return

    setLoadingField(field)
    const userRequest = { ...userData, [field]: text }
    setUserData(userRequest)
    updateUser(userRequest)
    setTimeout(() => {
      setLoadingField("")
    }, 100)
  }

  useEffect(() => {
    setLoading(false)
    if (user) {
      setUserData(user)
      setSystemOfMeasurement(user && user.system_of_measurement)
    }
  }, [user])

  return (
    <Page isSettings showHeader headerValue={sidebarT("user")}>
      <>
        <Col xs={12} md={6} lg={4}>
          <InputField
            name={"field-1"}
            label={t("first_name")}
            value={user?.firstname || ""}
            onPersist={onInputPersist("firstname")}
            loading={loadingField == "firstname"}
          />
          <InputField
            name={"field-2"}
            label={t("last_name")}
            value={user?.lastname || ""}
            onPersist={onInputPersist("lastname")}
            loading={loadingField == "lastname"}
          />
          <>
            <b className="small">{t("unit")}:</b>
            <div className="d-flex flex-row align-items-center">
              <DropdownButton
                className="mt-2 me-2"
                id="time-interval-picker"
                title={systemOfMeasurement ? t(`${systemOfMeasurement}`) : t("select_unit")}>
                {units.map((item, i) => (
                  <Dropdown.Item
                    key={item}
                    eventKey={i}
                    onClick={() => {
                      if (!userData?.id) return
                      setLoading(true)
                      const userRequest = { ...userData, system_of_measurement: item }
                      updateUser(userRequest)
                      setSystemOfMeasurement(item)
                    }}>
                    {t(`${item}`)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {loading && <Spinner style={sortArrowStyles.loader} animation="border" className="mt-2" />}
            </div>
          </>
        </Col>
      </>
    </Page>
  )
}
