import {
  getDeviceSerialnumber,
  getDeviceSerialnumberTrips,
  getLatestVehicleInsights,
  patchDeviceSerialNumber as patchDeviceData,
} from "@src/api"
import { getiLockitLockStatus } from "@src/api/ilockit"
import { TRIP } from "@src/api/types"
import { DEVICE_TYPE } from "@src/devices/types"
import { usePartner } from "@src/partner"
import React, { createContext, useContext, useState } from "react"

type DEVICE_CONTEXT = {
  device: DEVICE_TYPE | undefined
  trips: TRIP[]
  loadDevice: (serialNumber: string) => void
  loadLastTrips: (serialNumber: string) => void
  patchDevice: (deviceData: DEVICE_TYPE) => Promise<boolean>
  clearDevice: () => void
  clearLastTrips: () => void
}

const DEFAULT_DEVICE_CONTEXT = {
  device: undefined,
  trips: [] as TRIP[],
  loadDevice: () => {},
  loadLastTrips: () => {},
  patchDevice: async () => false,
  clearDevice: () => {},
  clearLastTrips: () => {},
}

export const DeviceContext = createContext<DEVICE_CONTEXT>(DEFAULT_DEVICE_CONTEXT)

export const DeviceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { partner } = usePartner()
  const [device, setDevice] = useState<DEVICE_TYPE>()
  const [trips, setTrips] = useState<TRIP[]>([])

  const loadDevice = (serialNumber: string) => {
    if (!partner) return
    getDeviceSerialnumber(partner?.uuid, serialNumber).then(({ response, data }) => {
      if (response.ok && data) {
        Promise.allSettled([
          getiLockitLockStatus(partner?.uuid, serialNumber),
          getLatestVehicleInsights(partner?.uuid, serialNumber),
        ]).then(([keyHubResponse, CANResponse]) => {
          if (
            keyHubResponse.status === "fulfilled" &&
            keyHubResponse.value.data &&
            CANResponse.status === "fulfilled" &&
            CANResponse.value.data?.success
          ) {
            setDevice({
              ...data,
              ilockitCredentials: keyHubResponse.value.data,
              latest_vehicle_insight: CANResponse.value.data.latest_vehicle_insight,
            })
          } else if (keyHubResponse.status === "fulfilled" && keyHubResponse.value.data) {
            setDevice({
              ...data,
              ilockitCredentials: keyHubResponse.value.data,
            })
          } else if (CANResponse.status === "fulfilled" && CANResponse.value.data?.success) {
            setDevice({
              ...data,
              latest_vehicle_insight: CANResponse.value.data.latest_vehicle_insight,
            })
          } else {
            setDevice(data)
          }
        })
      }
    })
  }

  const patchDevice = async (deviceData: DEVICE_TYPE) => {
    if (!partner) return false
    const { response } = await patchDeviceData(partner?.uuid, deviceData)
    if (response.ok) {
      setDevice(deviceData) //TO DO: The backend response of devicedata is incomplete. so we return the boolean of the success of the API to retain the changes.
      return true
    }
    return false
  }

  const loadLastTrips = (serialNumber: string) => {
    if (!partner) return
    getDeviceSerialnumberTrips(partner?.uuid, serialNumber, { per_page: 500 }).then(({ response, data }) => {
      if (response.ok && data) {
        setTrips(data.trips)
      }
    })
  }

  const clearDevice = () => {
    setDevice(undefined)
  }

  const clearLastTrips = () => {
    setTrips([])
  }

  const context = {
    device,
    trips,
    loadDevice,
    loadLastTrips,
    patchDevice,
    clearDevice,
    clearLastTrips,
  }

  return <DeviceContext.Provider value={context}>{children}</DeviceContext.Provider>
}

export const useDevice = () => useContext(DeviceContext)
