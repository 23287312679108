import { mailgun } from "@src/config"

export type emailProps = {
  from: string
  subject: string
  text: string
}

type MAILGUN_RESPONSE = {
  message: string
  id: string
}

export const sendEmailViaMailgun = async ({ from, subject, text }: emailProps): Promise<MAILGUN_RESPONSE> => {
  const headerReq = {
    Authorization: `Basic ${btoa(`api:${mailgun.apiKey}`)}`,
    Accept: "application/json",
  }

  const params = new URLSearchParams()
  params.append("from", from)
  params.append("to", mailgun.support_email)
  params.append("bcc", "")
  params.append("subject", subject)
  params.append("text", text)

  try {
    const response = await fetch(mailgun.mailgunEndPoint, {
      method: "POST",
      headers: headerReq,
      body: params,
    })
    return response.json()
  } catch (err) {
    console.log(err)
    throw err
  }
}

