import { useLocalization } from "@src/localization"
import React from "react"

export const PrivacyPolicyScreen: React.FC = () => {
  const { t } = useLocalization("privacy")

  return (
    <div className="container content">
      <h1>
        <b>{t("header")}</b>
      </h1>
      <br />
      <b>{t("general_information")}</b>
      <br />
      {t("general_information_text")}
      <br />
      <br />
      <b>{t("data_collection")}</b>
      <br />
      {t("data_collection_text")}
      <br />
      <br />
      <b>{t("tools")}</b>
      <br />
      {t("tools_text")}
      <br />
      <br />
      <b>{t("external_host")}</b>
      <br />
      {t("external_host_text")}
      <br />
      <br />
      {t("hoster")}
      <br />
      jweiland.net
      <br />
      Echterdinger Straße 57
      <br />
      70794 Filderstadt {t("near")} Stuttgart
      <br />
      <br />
      <b>{t("order_processing")}</b>
      <br />
      {t("order_processing_text")}
      <br />
      <br />
      <b>{t("data_protection")}</b>
      <br />
      {t("data_protection_text")}
      <br />
      <br />
      <b>{t("resp_office")}</b>
      <br />
      {t("resp_office_text")}
      <br />
      <br />
      IoT Venture GmbH
      <br />
      Hilpertstr. 31
      <br />
      64295 Darmstadt
      <br />
      Deutschland
      <br />
      {t("telephone")}: +49 800 0060751
      <br />
      {t("email")}: info@iot-venture.com
      <br />
      <br />
      {t("resp_office_footer")}
      <br />
      <br />
      <b>{t("storage_period")}</b>
      <br />
      {t("storage_period_text")}
      <br />
      <br />
      <b>{t("data_process")}</b>
      <br />
      {t("data_process_text")}
      <br />
      <br />
      <b>{t("data_protection_officer")}</b>
      <br />
      {t("data_protection_officer_text")}
      <br />
      <br />
      <b>{t("data_transfer")}</b>
      <br />
      {t("data_transfer_text")}
      <br />
      <br />
      <b>{t("revocation")}</b>
      <br />
      {t("revocation_text")}
      <br />
      <br />
      <b>{t("marketing")}</b>
      <br />
      {t("marketing_text")}
      <br />
      <br />
      <b>{t("competent_supervisor")}</b>
      <br />
      {t("competent_supervisor_text")}
      <br />
      <br />
      <b>{t("data_portability")}</b>
      <br />
      {t("data_portability_text")}
      <br />
      <br />
      <b>{t("deletion")}</b>
      <br />
      {t("deletion_text")}
      <br />
      <br />
      <b>{t("restrict_process")}</b>
      <br />
      {t("restrict_process_text")}
      <br />
      <br />
      <b>{t("ssl")}</b>
      <br />
      {t("ssl_text")}
      <br />
      <br />
      <b>{t("cookies")}</b>
      <br />
      {t("cookies_text")}
      <br />
      <br />
      <b>{t("contact_form")}</b>
      <br />
      {t("contact_form_text")}
      <br />
      <br />
      <b>{t("inquiry")}</b>
      <br />
      {t("inquiry_text")}
      <br />
      <br />
      <b>{t("google_analytics")}</b>
      <br />
      {t("google_analytics_text")}
      <br />
      <a href="https://google.com/businesses/controllerterms/mccs/">
        https://google.com/businesses/controllerterms/mccs/.
      </a>
      <br />
      <br />
      <b>{t("browser_plugins")}</b>
      <br />
      {t("browser_plugins_text")}
      <br />
      <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de.</a>
      <br />
      {t("browser_plugins_more")}
      <br />
      <a href="https://support.google.com/analytics/answer/6004245?hl=de">
        https://support.google.com/analytics/answer/6004245?hl=de.
      </a>
      <br />
      <br />
      <b>{t("data_processing")}</b>
      <br />
      {t("data_processing_text")}
      <br />
      <br />
      <b>{t("purpose_legal")}</b>
      <br />
      {t("purpose_legal_text")}
      <br />
      <br />
      <b>{t("storage_period_2")}</b>
      <br />
      {t("storage_period_2_text")}
      <br />
      <br />
      <b>{t("conference_tools")}</b>
      <br />
      {t("conference_tools_text")}
      <br />
      {t("microsoft_teams")}
      <br />
      {t("microsoft_teams_text")}
      <br />
      <a href="https://microsoft.com/de-de/privacystatement">https://microsoft.com/de-de/privacystatement.</a>
      <br />
      <br />
      <b>{t("applicant_data")}</b>
      <br />
      {t("applicant_data_text")}
      <br />
      <br />
      <b>{t("scope_data_collection")} </b>
      <br />
      {t("scope_data_collection_text")}
      <br />
      <br />
      <b>{t("rentention_period")} </b>
      <br />
      {t("rentention_period_text")}
      <br />
      <br />
      <b>{t("applicant_pool")} </b>
      <br />
      {t("applicant_pool_text")}
      <br />
      <br />
      <br />
    </div>
  )
}
