import { callApi } from "@src/api/callApi"
import { FLEETS, FLEETS_CONTACT } from "@src/settings"

type POST_FLEET_BODY = {
  fleet: {
    name: string
    contact_attributes?: FLEETS_CONTACT 
  }
}

export type POST_FLEET_RESPONSE = {
  fleet: FLEETS
}

export const postPartnerUuidFleets = (partnerUuid: string, body: POST_FLEET_BODY) =>
  callApi<POST_FLEET_BODY, POST_FLEET_RESPONSE>({
    method: "POST",
    path: `/partner/${partnerUuid}/fleets`,
    body,
  })
