import { deleteFleetsUuidRemoveDevices, getDevice, getFleetsUuidDevices, postFleetsUuidAddDevices } from "@src/api"
import { ModalView } from "@src/components/ModalView"
import { colors, config } from "@src/constants"
import { colorTagStyle } from "@src/device"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import debounce from "lodash/debounce"
import React, { useEffect, useState } from "react"
import { Form, ListGroup, Spinner } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useFleets } from "../fleets"
import { useFence } from "../geofence"
// eliminate enum by using string
export type SearchDeviceType = "Fleet" | "Fence"

type DevicesSelectTabProps = {
  type: SearchDeviceType
}

const styles = {
  divBox: {
    display: "flex",
    flexDirection: "column" as const,
    height: "350px",
  },
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
}

export const DevicesSelectTab: React.FC<DevicesSelectTabProps> = ({ type }) => {
  const { t } = useLocalization("settings")
  const { t: sidebarT } = useLocalization("sidebar")
  const { partner } = usePartner()
  const { fleet } = useFleets()
  const { loadFenceDevices, fenceDevices } = useFence()
  const [loading, setLoading] = useState<boolean>(false)
  const [results, setResults] = useState<SLIM_DEVICE_TYPE[]>([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SLIM_DEVICE_TYPE[]>()
  const [selectedDevice, setSelectedDevice] = useState<SLIM_DEVICE_TYPE | undefined>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const { fleetUuid } = useParams()
  const [searchText, setSearchText] = useState<string>("")

  useEffect(() => {
    setLoading(true)
    if (partner) {
      getDevicesList()
    }
    if (fenceDevices && type == "Fence") {
      setLoading(false)
    }
  }, [searchText, partner, fenceDevices])

  useEffect(() => {
    if (selectedCheckboxes && searchText == "") sortArray(results)
  }, [selectedCheckboxes])

  const getDevicesList = () => {
    if (partner) {
      getDevice(partner?.uuid, undefined, {
        page: 0,
        per_page: config.itemsPerPage,
        search_string: searchText,
      }).then(({ response, data }) => {
        if (response.ok && data) {
          setResults(data.devices)
          if (searchText !== "") setLoading(false)
          type == "Fleet" ? loadFleetDevices() : loadFenceDevices()
        }
      })
    }
  }

  useEffect(() => {
    if (fenceDevices && type == "Fence" && results.length !== 0) {
      setDevices(fenceDevices)
      setSelectedCheckboxes(fenceDevices)
    }
  }, [fenceDevices])

  const loadFleetDevices = () => {
    if (partner && fleetUuid) {
      getFleetsUuidDevices(partner.uuid, fleetUuid, {
        page: 0,
        per_page: config.itemsPerPage,
      }).then(({ response, data }) => {
        if (response.ok && data) {
          setLoading(false)
          setDevices(data.devices)
          setSelectedCheckboxes(data.devices)
        }
      })
    }
  }

  const handleCheckbox = (result: SLIM_DEVICE_TYPE) => {
    setSelectedDevice(result)
    if (fleet && result.fleet?.uuid !== fleet.uuid) {
      setShowModal(true)
    } else {
      const checkedBoxes = selectedCheckboxes ? [...selectedCheckboxes] : []
      if (checkedBoxes.some((ch) => ch.serialnumber === result.serialnumber)) {
        const index = checkedBoxes.findIndex((ch) => ch.serialnumber === result.serialnumber)
        removeDevice(checkedBoxes[index], false)
        checkedBoxes.splice(index, 1)
      } else {
        checkedBoxes.push(result)
        addDevice(result)
      }
    }
  }

  const addDevice = (device: SLIM_DEVICE_TYPE) => {
    if (type == "Fleet") {
      if (partner && fleetUuid) {
        postFleetsUuidAddDevices(partner.uuid, fleetUuid, { device_serialnumbers: [device.serialnumber] }).then(
          ({ response }) => {
            if (response.ok) {
              setSearchText("")
              loadFleetDevices()
            }
          },
        )
      }
    }
    // else {
    //   if (partner) {
    //     putFleetsUuidFencesIdAddDevices(partner.uuid, fleet.uuid, fence.id, {
    //       device_serialnumbers: [device.serialnumber],
    //     }).then(() => {
    //       setSearchText("")
    //       loadFenceDevices()
    //     })
    //   }
    // }
  }

  const removeDevice = (device: SLIM_DEVICE_TYPE, isRemoveAdd: boolean) => {
    if (type == "Fleet") {
      if (partner && fleetUuid) {
        deleteFleetsUuidRemoveDevices(partner.uuid, isRemoveAdd && device.fleet ? device.fleet.uuid : fleetUuid, {
          device_serialnumbers: [device.serialnumber],
        }).then(({ response }) => {
          if (response.ok) {
            if (isRemoveAdd) addDevice(device)
            setSearchText("")
            loadFleetDevices()
          }
        })
      }
    }
    // else {
    //   if (partner) {
    //     deleteFleetsUuidFencesIdRemoveDevices(partner.uuid, fleet.uuid, fence.id, {
    //       device_serialnumbers: [device.serialnumber],
    //     }).then(() => {
    //       setSearchText("")
    //       loadFenceDevices()
    //     })
    //   }
    // }
  }

  const sortArray = (deviceArray: SLIM_DEVICE_TYPE[]) => {
    devices.map((device) => {
      const findIndex = deviceArray.findIndex((item) => item.serialnumber == device.serialnumber)
      deviceArray.splice(findIndex, 1)
    })
    if (searchText == "") setResults([...(selectedCheckboxes || []), ...deviceArray])
    setSelectedDevice(undefined)
    setLoading(false)
  }

  return (
    <div>
      <Form.Control
        type="search"
        id="search"
        autoComplete="off"
        placeholder={sidebarT("search_placeholder")}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value.toString())
          debounce(getDevicesList, 2000)
        }}
      />
      <div style={styles.divBox}>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <ListGroup className="mt-2" style={styles.listGroup}>
            {results.map((result) => (
              <ListGroup.Item
                key={result.serialnumber}
                onClick={() => {
                  handleCheckbox(result)
                }}>
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    {selectedDevice && selectedDevice.serialnumber == result.serialnumber ? (
                      <div style={sortArrowStyles.loader} />
                    ) : (
                      <input
                        type="checkbox"
                        id={`device${result.serialnumber}`}
                        name="deviceId"
                        checked={
                          selectedCheckboxes && selectedCheckboxes.some((ch) => ch.serialnumber === result.serialnumber)
                        }
                        onChange={() => {}}
                      />
                    )}
                  </div>
                  <label className="ms-2">
                    {result.serialnumber} - {result.name}
                  </label>
                  {type == "Fleet" && fleet && result.fleet && result.fleet.uuid !== fleet.uuid && (
                    <b className="ms-2" style={colorTagStyle(colors.red)}>
                      {" "}
                      ({result.fleet.name})
                    </b>
                  )}
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {selectedDevice && fleet && type == "Fleet" && (
          <ModalView
            title={t("fleets.alert")}
            buttonTitle={t("fleets.add")}
            show={showModal}
            buttonLoader={false}
            onHideModal={() => {
              setSelectedDevice(undefined)
              setShowModal(false)
            }}
            onButtonClick={() => {
              setShowModal(false)
              const updatedCheckboxes = selectedCheckboxes ? [...selectedCheckboxes, selectedDevice] : [selectedDevice]
              setSelectedCheckboxes(updatedCheckboxes)
              removeDevice(selectedDevice, true)
            }}>
            <label>
              {t("fleets.adding")} {selectedDevice.name} {t("fleets.in")} <b>{fleet.name}</b>{" "}
              {t("fleets.willremovefrom")}{" "}
              <b style={colorTagStyle(colors.red)}>{selectedDevice.fleet && selectedDevice.fleet.name}</b>
            </label>
          </ModalView>
        )}
      </div>
    </div>
  )
}
