import { putPartnerUuidFleetsUuid } from "@src/api"
import { InputField, Page } from "@src/components"
import { usePartner } from "@src/partner"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { FleetsTabs } from "./tabs"
import { useFleets } from "./useFleets"

export const FleetsDetailScreen: React.FC = () => {
  const { fleet, updateFleets } = useFleets()
  const { partner } = usePartner()
  const [loadingField, setLoadingField] = useState<string>("")
  const onInputPersist = (field: string) => (text: string) => {
    setLoadingField(field)
    const fleetData = { fleet: { name: text } }
    if (partner && fleet && fleetData) {
      putPartnerUuidFleetsUuid(partner.uuid, fleet.uuid, fleetData).then(({ response }) => {
        if (response.ok) updateFleets()
        setLoadingField("")
      })
    }
  }

  return (
    <div>
      <Page isSettings={true}>
        <div>
          <Row>
            <Col xs={12} md={6} lg={4}>
              <InputField
                value={fleet?.name || ""}
                onPersist={onInputPersist("name")}
                loading={loadingField == "name"}
              />
            </Col>
          </Row>
          <FleetsTabs />
        </div>
      </Page>
    </div>
  )
}
