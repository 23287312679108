import { getDevice } from "@src/api"
import { LockUnlockIcon, ModalView, Page, PaginationItem } from "@src/components"
import { colors } from "@src/constants"
import { SLIM_DEVICE_TYPE, TCAN_DATA } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import React, { CSSProperties, useEffect, useState } from "react"
import { Alert, Form, Spinner, Table } from "react-bootstrap"
import { BatteryCharging, Flag, LightningChargeFill } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { SortArrowIcon } from "./devicedetail/tabs/components"
import { useDevice } from "./useDevice"

const NOWRAP: CSSProperties = { whiteSpace: "nowrap" }
const DEVICES_LENGTH = [20, 50, 100]

export const colorTagStyle = (colors: string) => ({
  color: colors,
})

export const DevicesScreen: React.FC = () => {
  const navigate = useNavigate()
  const { formatDateTime, formatOdometer } = useLocalization()
  const { t } = useLocalization("device_screen")
  const { t: settingsT } = useLocalization("settings.fleets")
  const { t: sidebarT } = useLocalization("sidebar")
  const { partner, devicesLength, updateDeviceLength, permissions } = usePartner()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [activePage, setActivePage] = useState<number>(0)
  const [pagesCount, setPagesCount] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(true)
  const [sorting, setSorting] = useState<boolean>(true)
  const [sortHeader, setSortedHeader] = useState<string>("last_seen_timestamp")
  const [headerLoad, setHeaderLoad] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const { fleet } = useFleets()
  const { loadDevice, clearDevice, clearLastTrips } = useDevice()
  const [showVehicleInsights, setShowVehicleInsights] = useState<boolean>(false)
  const TABLE_COLUMNS = [
    { key: "locked", value: "" },
    { key: "serialnumber", value: "serialnumber" },
    { key: "name", value: "device_name" },
    { key: "battery", value: "batterie_status" },
    { key: "servicebooking", value: "active_service" },
    { key: "last_position_timestamp", value: "last_position" },
    { key: "last_seen_timestamp", value: "last_seen" },
  ]
  const [tableColumns, setTableColumns] = useState(TABLE_COLUMNS)
  useEffect(() => {
    if (showVehicleInsights) {
      const newColumns = [
        ...TABLE_COLUMNS,
        { key: "battery_state_of_charge", value: "bike_state_of_charge" },
        { key: "battery_state_of_health", value: "bike_battery_state_of_health" },
        { key: "odometer", value: "bike_odometer" },
      ]
      setTableColumns(newColumns)
    } else setTableColumns(TABLE_COLUMNS)
  }, [showVehicleInsights])
  useEffect(() => {
    partner && changePage(0)
  }, [partner?.uuid, permissions, headerLoad])

  useEffect(() => {
    setLoading(true)
    listDevices()
    clearDevice()
    clearLastTrips()
  }, [sorting, sortHeader, activePage, fleet, devicesLength])

  const handleOnClick = (item: SLIM_DEVICE_TYPE, event: React.MouseEvent<HTMLTableRowElement>) => {
    loadDevice(item.serialnumber)
    if (event.metaKey || event.ctrlKey) {
      const win = window.open(`/${partner?.uuid}/device/${item.serialnumber}`, "_blank")
      win?.focus()
    } else {
      navigate(`/${partner?.uuid}/device/${item.serialnumber}`)
    }
  }

  const listDevices = () => {
    if (partner?.uuid)
      getDevice(partner?.uuid, fleet && fleet.uuid, {
        page: activePage,
        per_page: devicesLength,
        sort_desc: sorting,
        sort_by: sortHeader,
      }).then(({ response, data }) => {
        if (response.ok && data) {
          setDevices(data.devices)
          if (data.meta.total && data.meta.per_page) {
            setPagesCount(Math.ceil(data.meta.total / data.meta.per_page))
          }
        }
        setLoading(false)
        setHeaderLoad(false)
      })
  }

  const changePage = (page: number) => {
    if ((page < 0 || pagesCount < page) && !!partner) return
    setLoading(activePage !== page)
    setActivePage(page)
  }

  const onClickTableHeader = (selectedHeader: string) => {
    selectedHeader !== sortHeader ? setSorting(true) : setSorting(!sorting)
    if (selectedHeader !== "servicebooking") {
      setSortedHeader(selectedHeader)
      setHeaderLoad(true)
    }
  }
  // if (!permissions?.can_see_device_list) return <PermissionCard />
  const renderCANDataColumns = (data: TCAN_DATA) => {
    if (!data) {
      return (
        <>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </>
      )
    }
    const {
      battery_state_of_charge,
      battery_state_of_charge_unit,
      battery_state_of_health,
      battery_state_of_health_unit,
      odometer,
      odometer_unit,
    } = data
    return (
      <>
        <td>{(battery_state_of_charge && `${battery_state_of_charge} ${battery_state_of_charge_unit}`) || "-"}</td>
        <td>{(battery_state_of_health && `${battery_state_of_health} ${battery_state_of_health_unit}`) || "-"}</td>
        <td>{(odometer && formatOdometer({ odometer: odometer, odometer_unit: odometer_unit })) || "-"}</td>
      </>
    )
  }
  return (
    <Page
      showFleet
      showHeader
      headerValue={sidebarT("devices")}
      onSelectFleet={() => {
        setLoading(true)
        setDevices([])
      }}>
      <div className="d-flex flex-row align-items-start">
        <PaginationItem activePage={activePage} pagesCount={pagesCount} onChangePage={(page) => changePage(page)} />
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <div className="ms-2">
            <Form.Select
              className="d-flex"
              name="fleets"
              id="fleets"
              onChange={(event) => {
                const eventTargetValue = parseInt(event.target.value)
                setActivePage(0)
                eventTargetValue > 50 ? setShowModal(true) : updateDeviceLength(eventTargetValue)
              }}
              defaultValue={devicesLength}>
              {DEVICES_LENGTH.map((item) => (
                <option key={item} value={item} selected={item == devicesLength}>
                  {item}
                </option>
              ))}
            </Form.Select>
          </div>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Form.Switch
              className={`ms-2 customSwitch`}
              id="custom-switch"
              label={<span>{t("show_vehicle_insights")}</span>}
              checked={showVehicleInsights}
              onChange={(e) => setShowVehicleInsights(e.currentTarget.checked)}
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="d-flex justify-content-center mt-4 mb-4">
          <Spinner animation="border" />
        </div>
      )}
      {devices.length == 0 && !loading && <Alert variant={"secondary"}>{t("no_device")}</Alert>}
      {devices.length > 0 && !loading && (
        <Table hover responsive>
          <thead>
            <tr style={NOWRAP}>
              {tableColumns.map(({ key, value }) => (
                <th
                  key={key}
                  className={key !== "servicebooking" ? "pointer" : ""}
                  onClick={() => onClickTableHeader(key)}>
                  {value && (
                    <div className="d-flex flex-row align-items-center">
                      {t(`${value as keyof typeof t}`)}
                      {sortHeader == key && (
                        <SortArrowIcon
                          loading={headerLoad}
                          arrowUp={tableColumns.map((column) => column.key).includes(key) ? !sorting : sorting}
                        />
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {devices &&
              devices.map((device, i) => (
                <tr key={i} onClick={(event) => handleOnClick(device, event)} style={NOWRAP} className="pointer">
                  <td>
                    {!device.reported_theft ? (
                      <LockUnlockIcon locked={device.locked} />
                    ) : (
                      <Flag style={colorTagStyle(colors.red)} />
                    )}
                  </td>
                  <td>
                    <div className="d-flex flex-row justify-content-between">
                      <div>{device.serialnumber}</div>
                      <div>{device.powersaving_ends_at && <LightningChargeFill color={colors.mustardYellow} />}</div>
                    </div>
                  </td>
                  <td>{device.name}</td>
                  <td>
                    <div className="d-flex align-items-center justify-content-center">
                      <label>{device.battery} %</label>
                      {device.charging && <BatteryCharging className="ms-1" color={colors.green} size={20} />}
                    </div>
                  </td>
                  <td>
                    {(device?.servicebooking && formatDateTime(new Date(device.servicebooking.service_end_date))) ||
                      "-"}
                  </td>
                  <td>
                    {(device?.servicebooking &&
                      device?.last_position_timestamp &&
                      formatDateTime(new Date(device.last_position_timestamp))) ||
                      "-"}
                  </td>
                  <td>
                    {(device?.servicebooking &&
                      device?.last_seen_timestamp &&
                      formatDateTime(new Date(device.last_seen_timestamp))) ||
                      "-"}
                  </td>
                  {showVehicleInsights && renderCANDataColumns(device?.latest_vehicle_insight)}
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      <ModalView
        title={settingsT("alert")}
        buttonTitle={t("ok")}
        show={showModal}
        buttonLoader={false}
        onHideModal={() => setShowModal(false)}
        onButtonClick={() => {
          setShowModal(false)
          updateDeviceLength(100)
        }}>
        <label>{t("pagination_msg")}</label>
      </ModalView>
    </Page>
  )
}
