import { DEVICE_TYPE } from "@src/devices/types"
import { callApi } from "../callApi"

export type GET_DEVICE_SERIALNUMBER_RESPONSE = DEVICE_TYPE

export const getDeviceSerialnumber = async (partnerUuid: string, serialnumber: string) =>
  callApi<null, GET_DEVICE_SERIALNUMBER_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/device/${serialnumber}`,
  })
