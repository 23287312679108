import { FLEETS } from "@src/settings"
import { callApi } from "../callApi"

type GET_FLEETS_PARAMS = {
  page?: number
  per_page?: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
}

export type GET_FLEETS_RESPONSE = {
  fleets: FLEETS[]
  meta: {
    page: number
    per_page: number
    total: number
    search_string: string
    sort_by: string
    sort_desc: string
  }
}

export const getPartnerUuidFleets = async (partnerUuid: string, params: GET_FLEETS_PARAMS) => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined) 
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) 
    .join("&");
  return callApi<null, GET_FLEETS_RESPONSE>({ method: "GET", path: `/partner/${partnerUuid}/fleets?${queryString}` })
}
