import { CREATE_PARTNER, postPartner } from "@src/api"
import { InputField } from "@src/components"
import { useLocalization } from "@src/localization"
import { PartnerDetail } from "@src/settings/partner/tabs/PartnerDetail"
import React, { useState } from "react"
import { Button, Col, Form, Row, Spinner } from "react-bootstrap"

type CreatePartnerProps = {
  onPartnerCreated: () => void
  onCancel: () => void
}

export const CreatePartner: React.FC<CreatePartnerProps> = ({ onPartnerCreated, onCancel }) => {
  const [partnerData, setPartnerData] = useState<CREATE_PARTNER>({ name: "", logo_url: "", color: "000000" })
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useLocalization("select_partner_screen")
  const { t: settingsT } = useLocalization("settings.fleets")

  const onInputPersist = (field: string) => (text: string) => {
    const partnerDataFill = { ...partnerData, [field]: text }
    setPartnerData(partnerDataFill)
  }

  const createPartner = async () => {
    setLoading(true)
    const createPartnerResponse = await postPartner({ partner: partnerData })
    if (createPartnerResponse.data) {
      setLoading(false)
      onPartnerCreated()
    }
  }

  return (
    <div className="m-3">
      <Form.Label className="fw-bold align-self-center mt-2 mb-2">{t("create_partner").toUpperCase()}</Form.Label>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <Row className="mt-3">
            <div className="d-flex">
              <b className="small">{t("name")}</b>
            </div>
            <Col>
              <InputField value={""} onPersist={onInputPersist("name")} loading={false} />
            </Col>
          </Row>

          <PartnerDetail
            isCreatePartner={true}
            onColorChange={(color) => {
              const partnerDataFill = {
                ...partnerData,
                color: color,
              }
              setPartnerData(partnerDataFill)
            }}
            onLogoChange={(logoUrl) => {
              const partnerDataFill = { ...partnerData, logo_url: logoUrl }
              setPartnerData(partnerDataFill)
            }}
          />
          <div className="d-flex flex-row-reverse">
            <div>
              <Button
                className="btn btn-primary mt-2 me-2"
                disabled={!partnerData?.name || !partnerData?.color || !partnerData?.logo_url}
                onClick={() => {
                  createPartner()
                }}>
                {t("create")}
              </Button>
              <Button
                className="btn btn-primary-outline mt-2"
                onClick={() => {
                  onCancel()
                }}>
                {settingsT("cancel")}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
