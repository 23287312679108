import { callApi } from "@src/api/callApi"
import { PARTNER } from "@src/partner"

export type CREATE_PARTNER = {
  name: string
  color: string
  logo_url: string
}

type POST_PARTNER_BODY = {
  partner: CREATE_PARTNER
}

export const postPartner = (body: POST_PARTNER_BODY) =>
  callApi<POST_PARTNER_BODY, PARTNER>({
    method: "POST",
    path: `/partner`,
    body,
  })
