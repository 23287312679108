import { localization } from "@src/config"
import { useLocalization } from "@src/localization/useLocalization"
import { useUser } from "@src/user"
import React from "react"
import { NavDropdown } from "react-bootstrap"
import "./LanguagePicker.css"

const CONTAINER = { marginRight: "5px", marginTop: "2px" }

export const LanguagePicker: React.FC = () => {
  const { language, changeLanguage } = useLocalization()
  const { user, updateUser } = useUser()

  const changeEvent = (selectedKey: string) => {
    if (user) {
      const userRequest = { ...user, langcode: selectedKey }
      updateUser(userRequest)
    }
    changeLanguage(selectedKey)
  }

  return (
    <NavDropdown
      id="language-picker"
      title={language.toUpperCase()}
      onSelect={(eventKey) => eventKey && changeEvent(eventKey)}
      style={CONTAINER}>
      {localization.languages.map((langItem, i) => (
        <NavDropdown.Item
          href="#"
          key={i}
          eventKey={langItem}
          active={langItem == language}
          className={"language-picker-item align-self-center"}>
          {langItem.toUpperCase()}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )
}
