import { colors } from "@src/constants"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { useEffect } from "react"
import { Card } from "react-bootstrap"
import { useParams } from "react-router-dom"

export const PermissionCard: React.FC = () => {
  const styles = { warningCard: { backgroundColor: colors.whiteGray } }
  const { t } = useLocalization("select_partner_screen")
  const { partnerUuid } = useParams()
  const { partner, changePartner } = usePartner()

  useEffect(() => {
    if (partner && partnerUuid && partner?.uuid !== partnerUuid) {
      changePartner(partnerUuid)
    }
  }, [partner])

  if (partner)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Card style={styles.warningCard} className="mt-4 p-2">
          {t("warning_role")}
        </Card>
      </div>
    )
}
