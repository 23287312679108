import { useLocalization } from "@src/localization"
import React from "react"
import { Badge } from "react-bootstrap"
import { Link } from "react-router-dom"
import packageJson from "./../../package.json"

const Separator: React.FC = () => <span className="text-white"> | </span>

const Footer: React.FC = () => {
  const { t } = useLocalization("login_screen")
  return (
    <footer id="footer">
      <div className="container">
        <ul>
          <Link to="/legal/privacy">{t("privacy")}</Link>
          <Separator />
          <Link to="/legal/imprint">{t("imprint")}</Link>
          <Separator />
          <Badge bg="secondary">v{packageJson.version}</Badge>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
