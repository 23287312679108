import { useLocalization } from "@src/localization"
import React from "react"

export const ImprintScreen: React.FC = () => {
  const { t } = useLocalization("imprint")
  const { t: t_login } = useLocalization("login_screen")
  return (
    <div className="container content">
      <h1>{t_login("imprint")}</h1>
      <p>
        IoT Venture GmbH
        <br />
        c/o Hub 31
        <br />
        Hilpertstr. 31
        <br />
        64295 Darmstadt
        <br />
        Deutschland
        <br />
        <br />
        T: +49 800 0060751
        <br />
        E: info@iot-venture.com
        <br />
        <br />
        {t("directors")}
        <br />
        Thomas Ullmann, Jürgen Veith
        <br />
        <br />
        {t("court_number")} HRB-Nr. 95191
        <br />
        {t("tax_id_number")}: DE306665167
        <br />
        <br />
        {t("pages_act")}:
        <br />
        <br />
        Jürgen Veith
        <br />
        Geschäftsführer
        <br />
        IoT Venture GmbH
        <br />
        Hilpertstraße 31
        <br />
        64295 Darmstadt
        <br />
        Deutschland
        <br />
        <br />
        {t("images")}:
        <br />
        {t("legal_images")} <a href="https://www.gettyimages.de">gettyimages.de</a>
        {t("verwendet")}.
        <br />
        <br />
        <br />
        <b>{t("concept")}</b>
        <br />
        SCHUMACHER – Brand + Interaction Design GmbH
        <br />
        <a href="https://www.schumacher-design.de">https://www.schumacher-design.de</a>
        <br />
        <br />
        <br />
        {t("footer_info")}
        <br />
        <br />
      </p>
    </div>
  )
}
