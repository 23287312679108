import { getFleetsUuidDevices, postFleetsUuidBatchDisablePowersave, postFleetsUuidBatchEnablePowersave } from "@src/api"
import { FleetDropDown, Page } from "@src/components"
import { colors, config } from "@src/constants"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { FLEETS, useFleets } from "@src/settings"
import React, { useEffect, useState } from "react"
import { Alert, Button, Card, Dropdown, DropdownButton, Spinner } from "react-bootstrap"

const styles = { warningCard: { backgroundColor: colors.whiteGray } }

export const EnergySaving: React.FC = () => {
  const { t: t_device } = useLocalization("device_screen")
  const { t: t_settings } = useLocalization("settings")
  const { t: t_side_bar } = useLocalization("sidebar")
  const { partner } = usePartner()
  const { fleets } = useFleets()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [apiLoader, setApiLoader] = useState<boolean>(false)
  const [timeInterval, setTimeInterval] = useState<number>(4)
  const [selectedFleet, setSelectedFleet] = useState<FLEETS>()
  const [batchOnOff, setBatchOnOff] = useState<boolean>(false)
  const [alertShow, setAlertShow] = useState<boolean>(false)
  const powerSaveIntervals = [4, 8, 24]

  useEffect(() => {
    if (fleets) getFleetsDevices(fleets[0])
  }, [fleets])

  const getFleetsDevices = (fleet: FLEETS) => {
    setSelectedFleet(fleet)
    setLoading(true)
    if (partner && fleet) {
      getFleetsUuidDevices(partner.uuid, fleet.uuid, {
        page: 0,
        per_page: config.itemsPerPage,
      }).then(({ response, data }) => {
        if (response.ok && data) {
          setDevices(data.devices)
          const devices = data.devices.filter((device) => device.powersaving_ends_at !== null)
          if (devices.length == data.devices.length) {
            setBatchOnOff(true)
          } else {
            setBatchOnOff(false)
          }
          setLoading(false)
        }
      })
    }
  }

  const alertAnimation = () => {
    setAlertShow(true)
    setTimeout(() => {
      setAlertShow(false)
    }, config.alertSuccessTimeOut)
  }

  const powerSaveOnFleets = async () => {
    setApiLoader(true)
    setAlertShow(false)
    if (selectedFleet && partner) {
      if (batchOnOff) {
        const response = await postFleetsUuidBatchDisablePowersave(partner.uuid, selectedFleet.uuid)
        if (response.response.ok) {
          setApiLoader(false)
          setBatchOnOff(false)
        }
      } else {
        const response = await postFleetsUuidBatchEnablePowersave(partner.uuid, selectedFleet.uuid, {
          interval: timeInterval,
        })
        if (response.response.ok) {
          setApiLoader(false)
          setBatchOnOff(true)
        }
      }
    }
    alertAnimation()
  }

  // if (!partner?.permissions?.can_see_energy_saving) return <PermissionCard />

  return (
    <Page showHeader headerValue={t_side_bar("energy_saving")}>
      <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
        {t_device("power_saving.energy_limitations_msg")}
      </Card>

      {fleets.length !== 0 ? (
        <>
          <b>{t_settings("fleets.select_fleet")}</b>
          <div className="d-flex mt-2 mb-3 flex-row">
            <div>
              <FleetDropDown
                withoutAllOption
                fleetUpdate={false}
                onClick={(fleet) => {
                  if (fleet) {
                    setDevices([])
                    setSelectedFleet(fleet)
                    setAlertShow(false)
                    getFleetsDevices(fleet)
                  }
                }}
              />
            </div>
            <div className="mt-2 ms-2">
              {loading ? (
                <Spinner style={sortArrowStyles.loader} animation="border" />
              ) : (
                <b>
                  ({devices.length} {t_side_bar("devices")})
                </b>
              )}
            </div>
          </div>
          {!batchOnOff && (
            <>
              <b>{t_device("power_saving.select_interval")}</b>
              <DropdownButton
                className="mt-2"
                id="time-interval-picker"
                title={`${timeInterval} ${t_device("power_saving.hours")}`}>
                {powerSaveIntervals.map((item, i) => (
                  <Dropdown.Item key={item} eventKey={i} onClick={() => setTimeInterval(item)}>
                    {item} {t_device("power_saving.hours")}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </>
          )}
          <div className="mt-3">
            <Button
              disabled={devices.length == 0 || apiLoader}
              className="btn btn-primary-outline"
              onClick={() => powerSaveOnFleets()}>
              <>
                {batchOnOff ? t_device("power_saving.energy_save_off") : t_device("power_saving.energy_save_on")}
                {apiLoader && <Spinner animation="border" size={"sm"} className="ms-2" />}
              </>
            </Button>
          </div>
        </>
      ) : (
        <b>{t_settings("fleets.no_fleets_found")}</b>
      )}
      {alertShow && (
        <Alert className="mt-3" transition={true} variant="success" dismissible onClose={() => setAlertShow(false)}>
          <label>
            {batchOnOff
              ? t_device("power_saving.success_message_turn_on")
              : t_device("power_saving.success_message_turn_off")}{" "}
            {selectedFleet && selectedFleet.name}
          </label>
        </Alert>
      )}
    </Page>
  )
}
