import { getDevice } from "@src/api"
import { Page, PermissionCard } from "@src/components"
import { config } from "@src/constants"
import { Map } from "@src/device/devicedetail/tabs/components"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings"
import React, { useEffect, useState } from "react"

export const MapScreen: React.FC = () => {
  const { t } = useLocalization("sidebar")
  const { partner, permissions } = usePartner()
  const [devices, setDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const { fleet } = useFleets()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    partner && fetchDevices()
  }, [partner?.uuid, fleet])

  const fetchDevices = async () => {
    setLoading(true)
    if (partner) {
      const { response, data } = await getDevice(partner.uuid, fleet?.uuid, { per_page: config.itemsPerPage })
      if (response.ok && data) {
        setDevices(data.devices)
        setLoading(false)
      }
    }
  }

  const mapDevices = devices.filter((device) => device.position !== null && !device.position.error)
  const mapDevicePositions = mapDevices.map((device) => device.position).filter((position) => position !== undefined)

  if (!permissions?.can_see_map) return <PermissionCard />

  return (
    <Page
      showFleet={true}
      showHeader={true}
      headerValue={t("maps")}
      onSelectFleet={() => {
        setLoading(true)
        setDevices([])
      }}>
      <Map
        position={mapDevicePositions[0]}
        datapoints={mapDevicePositions}
        showRoute={false}
        showMultipleMarkers={true}
        loadDevices={loading}
        mapDevices={mapDevices}
      />
    </Page>
  )
}
