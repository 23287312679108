import { callApi } from "../callApi"
import { POST_FLEET_DEVICE_BODY } from "./postFleetsUuidAddDevices"
import { POST_FLEET_RESPONSE } from "./postPartnerUuidFleets"

export const deleteFleetsUuidRemoveDevices = async (
  partnerUuid: string,
  fleetUuid: string,
  body: POST_FLEET_DEVICE_BODY,
) =>
  callApi<POST_FLEET_DEVICE_BODY, POST_FLEET_RESPONSE>({
    method: "POST",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/remove_devices`,
    body,
  })
