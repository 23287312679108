import { getUsersConfirmationToken } from "@src/api"
import { colors } from "@src/constants"
import { useLocalization } from "@src/localization"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { EnvelopeCheck } from "react-bootstrap-icons"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthentication } from "../useAuthentication"
import "./verify.css"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

export const VerifyUser: React.FC = () => {
  const { t } = useLocalization("verify_user")
  const { accessTokenId } = useParams()
  const [showError, setShowError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [verificationComplete, setVerificationComplete] = useState<boolean>(false)
  const navigate = useNavigate()
  const { logout } = useAuthentication()

  useEffect(() => {
    if (!accessTokenId) return
    logout()
    setLoading(true)
    getUsersConfirmationToken(accessTokenId)
      .then(({ response, data }) => {
        setLoading(false)
        if (!response.ok || data?.errors) return setShowError(true)

        setVerificationComplete(true)
        setShowError(false)
      })
      .catch(() => {
        setLoading(false)
        setShowError(true)
      })
  }, [accessTokenId, logout])

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="10" lg="8" className="shadow-lg bg-body p-4" style={COLUMN}>
          <div className="d-flex align-items-center justify-content-center m-2 flex-column">
            <EnvelopeCheck size={35} color={colors.black} />
            <b className="d-flex mt-2">{t("confirm_account")}</b>
            {loading ? (
              <div className={`circle-loader mt-3 ${verificationComplete ? "load-complete" : ""}`}>
                <div className="checkmark draw" style={{ display: verificationComplete ? "block" : "none" }}></div>
              </div>
            ) : (
              <div className="mt-3">
                <label>
                  {showError ? t("already_confirmed_email") : t("verify_msg")} {t("please")}{" "}
                  <b className="text-decoration-underline pointer" onClick={() => navigate("/login")}>
                    {t("click_here")}
                  </b>{" "}
                  {t("to_login")}
                </label>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}
