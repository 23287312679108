export const getTimeDiff = (laterDate: Date) => {
  const diff = laterDate.getTime() - new Date().getTime()
  let hours = new Date(diff).getHours() - 1
  if (laterDate.getDay() - new Date().getDay() == 1 && hours == -1) {
    hours = 23
  }
  const minutes = new Date(diff).getMinutes()
  return `${hours}h ${minutes}m`
}

export const convertToYYYYMMDD = (date: Date): string => {
  const formatter = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const parts = formatter.formatToParts(date);
  const year = parts.find((part) => part.type === "year")?.value;
  const month = parts.find((part) => part.type === "month")?.value;
  const day = parts.find((part) => part.type === "day")?.value;

  if (year && month && day) {
    return `${year}-${month}-${day}`;
  }
  throw new Error("Failed to format date");
};

export const parseYYYYMMDD = (dateString: string): Date => {
  const [year, month, day] = dateString.split("-").map(Number);

  if (!year || !month || !day) {
    throw new Error("Invalid date string format. Expected 'YYYY-MM-DD'.");
  }

  // Month is zero-based in JavaScript Date object (0 = January, 11 = December)
  return new Date(year, month - 1, day);
};