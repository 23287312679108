import { callApi } from "../callApi"
import { TRIP } from "../types"

type GET_DEVICE_SERIALNUMBER_TRIPS_PARAMS = {
  page?: number
  per_page?: number
  search_string?: string
  sort_by?: string
  sort_desc?: boolean
}

type GET_DEVICE_SERIALNUMBER_TRIPS_RESPONSE = {
  trips: TRIP[]
  meta: {
    page: number
    per_page: number
    total: number
    date_from: string
    sort_by: string
    sort_desc: string
    period: string
    start_period: string
    end_period: string
    prev_date: string
    next_date: string
  }
}

export const getDeviceSerialnumberTrips = async (
  partnerUuid: string,
  serialnumber: string,
  params: GET_DEVICE_SERIALNUMBER_TRIPS_PARAMS,
) => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== undefined) 
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`) 
    .join("&");
  return callApi<null, GET_DEVICE_SERIALNUMBER_TRIPS_RESPONSE>({
    method: "GET",
    path: `/partner/${partnerUuid}/device/${serialnumber}/trips?${queryString}`,
  })
}
