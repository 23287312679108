import { useLocalization } from "@src/localization"
import { FLEETS, useFleets } from "@src/settings"
import React, { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"

type FleetDropDownProps = {
  withoutAllOption?: boolean
  fleetUpdate?: boolean
  onClick: (fleet: FLEETS | undefined) => void
}

export const FleetDropDown: React.FC<FleetDropDownProps> = ({ onClick, withoutAllOption, fleetUpdate }) => {
  const { updateFleetUuid, fleets, fleet } = useFleets()
  const [updateFleet, setUpdateFleet] = useState<boolean>(true)
  const { t } = useLocalization("select_fleet_screen")
  const allDeviceOption = withoutAllOption ? "" : t("all_fleets")

  useEffect(() => {
    if (fleetUpdate) setUpdateFleet(fleetUpdate)
    if (withoutAllOption && fleets && fleets.length !== 0) {
      updateFleetUuid(fleets[0].uuid)
    }
  }, [fleetUpdate, withoutAllOption, fleets])

  const onSelectFleet = (fleet: FLEETS | undefined) => {
    const defaultFirstFleet = withoutAllOption ? fleets[0]?.uuid : ""
    if (updateFleet) updateFleetUuid(fleet ? fleet.uuid : defaultFirstFleet)
    fleet ? onClick(fleet) : onClick(undefined)
  }

  return (
    <>
      {fleets && fleets.length !== 0 && (
        <div>
          <Form.Select
            name="fleets"
            id="fleets"
            onChange={(event) => {
              const fleetItem = fleets.filter((el) => el.uuid === event.target.value)
              onSelectFleet(fleetItem[0])
            }}
            defaultValue={withoutAllOption ? fleets[0].uuid : fleet && fleet?.uuid ? fleet.uuid : allDeviceOption}>
            {fleets.map((item) => (
              <option key={item.uuid} value={item.uuid}>
                {item.name}
              </option>
            ))}
            {!withoutAllOption && (
              <option value={t("all_fleets")} onClick={() => onSelectFleet(undefined)}>
                {t("all_fleets")}
              </option>
            )}
          </Form.Select>
        </div>
      )}
    </>
  )
}
