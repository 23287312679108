import { callApi } from "@src/api/callApi"
import { FLEETS, FLEETS_CONTACT } from "@src/settings"

type PUT_FLEET_BODY = {
  fleet: {
    name: string
    contact_attributes?: FLEETS_CONTACT
  }
}

type PUT_FLEET_RESPONSE = {
  fleet: FLEETS
}

export const putPartnerUuidFleetsUuid = (partnerUuid: string, fleetUuid: string, body: PUT_FLEET_BODY) =>
  callApi<PUT_FLEET_BODY, PUT_FLEET_RESPONSE>({
    method: "PUT",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}`,
    body,
  })
