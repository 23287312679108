import { USER } from "@src/user"
import { callApi } from "../callApi"
import { ROLES_TITLE } from "./getPartnerUuidRoles"

export type INVITE_VERIFY_RESPONSE = {
  success: boolean
  invitation: {
    role: ROLES_TITLE
    email: string
    list_of_fleet_ids: []
    user: USER
    creator: USER
  }
  errors: string
}

export const getInvitationVerify = async (token: string) => {
  return callApi<null, INVITE_VERIFY_RESPONSE>({ method: "GET", path: `/invitation/verify?token=${token}` })
}
