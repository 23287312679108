import { getInvitationVerify } from "@src/api"
import { useAuthentication } from "@src/auth"
import { colors } from "@src/constants"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import { EnvelopePaper } from "react-bootstrap-icons"
import { useNavigate, useParams } from "react-router-dom"
import { useInvite } from "./useInvite"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

export const InviteUser: React.FC = () => {
  const { t } = useLocalization("invite_user")
  const { t: t_verify_user } = useLocalization("verify_user")
  const [verifyToken, setVerifyToken] = useState<boolean>(false)
  const [inviteFlow, setInviteFlow] = useState<boolean>(false)
  const { acceptTokenId } = useParams()
  const { updateInviteToken, acceptToken, declineToken } = useInvite()
  const { token, updateInvite, logout } = useAuthentication()
  const { updatePartners, partners } = usePartner()
  const [inviteCreater, setInviteCreater] = useState<string>("")
  const navigate = useNavigate()
  const [expired, setExpired] = useState<boolean>(false)

  useEffect(() => {
    updateInviteToken(acceptTokenId)
    if (token && acceptTokenId) {
      getInvitationVerify(acceptTokenId).then(({ response, data }) => {
        if (response.ok && data) {
          setVerifyToken(true)
          const createrName = data.invitation.creator?.firstname + " " + data.invitation.creator?.lastname
          setInviteCreater(createrName.charAt(0).toUpperCase() + createrName.slice(1))
          setExpired(false)
        } else {
          updateInvite(false)
          if (data && data.errors === "invitation expired") {
            setVerifyToken(true)
            setExpired(true)
          } else {
            navigate("/login")
          }
        }
      })
    }
  }, [token, acceptTokenId])

  useEffect(() => {
    if (partners && inviteFlow) {
      // if (partners?.length === 1) changePartner(partners[0].uuid)
      // else
      navigate("/")
    }
  }, [partners, inviteFlow, navigate])

  const renderSpinner = () => {
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spinner animation="border" />
      </div>
    )
  }

  const inviteAccept = async () => {
    setVerifyToken(false)
    const { response } = await acceptToken()
    if (response.ok) {
      updatePartners()
      setTimeout(() => {
        setInviteFlow(true)
        setVerifyToken(false)
      }, 500)
    }
  }

  const inviteDecline = async () => {
    setVerifyToken(false)
    const { response } = await declineToken()
    if (response.ok) {
      setInviteFlow(true)
      setVerifyToken(false)
    }
  }

  return (
    <Container>
      {verifyToken ? (
        <Row className="justify-content-center">
          <Col md="10" lg="8" className="shadow-lg bg-body p-4" style={COLUMN}>
            <div className="d-flex align-items-center justify-content-center m-2 flex-column">
              <EnvelopePaper size={35} color={colors.black} />
              <b className="d-flex  mt-2">{t("title")}</b>
              <div className="mt-1">
                <b className="d-flex  mt-2">
                  {!expired ? t("invite_user_msg", { value: inviteCreater }) : t("expired_message")}
                </b>
                {expired && (
                  <div className=" d-flex flex-row-reverse">
                    {t_verify_user("to_login")}
                    <b
                      className="text-decoration-underline pointer me-1"
                      onClick={() => {
                        logout()
                        navigate("/login")
                      }}>
                      {t_verify_user("click_here")}
                    </b>
                  </div>
                )}
                {!expired && (
                  <div className="mt-2 d-flex flex-row align-items-center justify-content-center">
                    <Button variant="success" onClick={inviteAccept}>
                      {t("accept")}
                    </Button>
                    <Button className="ms-1 btn btn-primary-outline" onClick={inviteDecline}>
                      {t("decline")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        renderSpinner()
      )}
    </Container>
  )
}
