import { localization } from "@src/config"
import translations from "./translations/translations"
import i18n from "./i18n"
import { mergeDeep } from "@src/utils"

export const validateLanguage = (lang: string) => {
  if (localization.languages.includes(lang)) return lang
  return localization.defaultLang
}

export const validateRegion = (region: string) => {
  if (localization.regions.includes(region)) return region
  return localization.defaultRegion
}
export const mergeTranslationWith = (whitelabelLanguage: object) => mergeDeep(translations, whitelabelLanguage)

export const initTranslation = <T extends PickDotPathByType<typeof translations.en>>(dotPath: T )=>
  (valueKey: PickValueKeysFromDotPath<typeof translations.en, typeof dotPath>, dynamicValues?: Record<string, unknown>) => 
    i18n.t(`${dotPath}.${valueKey}`, dynamicValues)  

type PickDotPathByType<
  TObject extends Record<string, unknown>,
  TTarget = Record<string, unknown>,
  CurrentPath extends string = "",
  Keys extends keyof TObject = keyof TObject
> = Keys extends Keys
  ? | (TObject[Keys] extends TTarget
          ? `${CurrentPath}${CurrentPath extends "" ? "" : "."}${Keys & string}`
          : never)
    | (TObject[Keys] extends Record<string, unknown>
          ? PickDotPathByType<
          TObject[Keys],
              TTarget,
              `${CurrentPath}${CurrentPath extends "" ? "" : "."}${Keys & string}`
            >
          : never)
  : never;

type PickValueKeysFromDotPath<
  T extends Record<string, unknown>,
  K extends PickDotPathByType<T>
> = K extends `${infer First}.${infer Rest}` | `${infer First}`
  ? First extends keyof T
    ? T[First] extends Record<string, unknown>
      ? Rest extends PickDotPathByType<T[First]>
        ? PickValueKeysFromDotPath<T[First], Rest>
        : PickDotPathByType<T[First], string | number>
      : never
    : never
  : never;