import { FENCE } from "@src/settings"
import { callApi } from "../callApi"

export type POST_FENCE_BODY = {
  fence: {
    fencingposts: string
    name?: string
    kind: string
    lat?: string
    lng?: string
    notification_kind?: string
    leaving_url?: string
    entering_url?: string
    direction?: string
  }
}

export type POST_FENCE_RESPONSE = {
  success: boolean
  fence: FENCE
  error: string
}

export const postFleetsUuidFences = async (partnerUuid: string, fleetUuid: string, body: POST_FENCE_BODY) => {
  return callApi<POST_FENCE_BODY, POST_FENCE_RESPONSE>({
    method: "POST",
    path: `/partner/${partnerUuid}/fleets/${fleetUuid}/fences`,
    body,
  })
}
