import { getDevice, sendEmailViaMailgun } from "@src/api"
import { useAuthentication } from "@src/auth"
import { ModalView } from "@src/components"
import { config } from "@src/constants"
import { SLIM_DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import { useFleets } from "@src/settings/fleets"
import { useUser } from "@src/user"
import React, { useEffect, useState } from "react"
import { Button, Card, Dropdown, DropdownButton, Form, ListGroup, Spinner } from "react-bootstrap"
import { useLocation } from "react-router"
import MultiSelect from "../components/MultiSelect"

const styles = {
  listGroup: {
    overflow: "scroll",
    flex: 1,
    display: "flex",
  },
  divBox: {
    display: "flex",
    flexDirection: "column" as const,
    height: "350px",
    marginBottom: "20px",
  },
  warningCard: { backgroundColor: "#f8f9fa" },
  toggleButton: { marginTop: "10px" },
  costBox: {
    display: "flex",
    flexDirection: "column" as const,
    marginTop: "15px",
    marginBottom: "15px",
  },
  filterBubble: {
    borderRedius: "20px",
    height: "35px",
    backgroundColor: "pink",
    display: "inline-block",
    padding: "5px",
    margin: "5px",
    justifyContent: "center",
    alignItems: "center",
  },
}

export const BookTrackerService: React.FC = () => {
  const { t } = useLocalization("settings.partner")
  const { t: sidebarT } = useLocalization("sidebar")
  const { userEmail, userId } = useAuthentication()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const { partner } = usePartner()
  const { fleet } = useFleets()
  const { user } = useUser()
  const [duration, setDuration] = useState<number>()
  const [searchText, setSearchText] = useState("")
  const [loading, setLoading] = useState(false)
  const [expiredDevices, setExpiredDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [expiringDevices, setExpiringDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SLIM_DEVICE_TYPE[]>([])
  const [selectedDevices, setSelectedDevices] = useState<SLIM_DEVICE_TYPE[]>([])
  const [optionId, setOptionId] = useState<number>(0)
  const tariff = [12, 24, 36]
  const { state } = useLocation()
  const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000
  const multiSelectRef = React.useRef<{ resetSelection: () => void } | undefined>()

  let hitApi = false

  useEffect(() => {
    const initializeState = async () => {
      setLoading(true)
      if (state) {
        if (state.devices) {
          setSelectedCheckboxes(state.devices)
          setSelectedDevices(state.devices)
        }
        if (state.id) {
          setOptionId(state.id)
          if (state.id === 1) {
            setExpiredDevices(state.devices)
          } else if (state.id === 2) {
            setExpiringDevices(state.devices)
          }
        }
        if (!state.serialnumber) {
          setLoading(false)
        }
      }
      if (partner && hitApi === false) {
        await fetchDevices()
      }
    }
    initializeState()
  }, [partner, fleet, state])

  const fetchDevices = async () => {
    hitApi = true
    if (partner?.uuid)
      getDevice(partner?.uuid, fleet && fleet.uuid, {
        page: 0,
        search_string: searchText,
        per_page: config.itemsPerPage,
      }).then(({ response, data }) => {
        if (response.ok && data) {
          const expiringDevices = data.devices.filter(
            (device) =>
              device.servicebooking &&
              new Date(device.servicebooking.service_end_date).getTime() - new Date().getTime() <=
                90 * DAY_IN_MILLISECONDS,
          )
          const expiredDevices = data.devices.filter((device) => device.servicebooking === null)

          if (state && state.serialnumber) {
            const selectedDevice = data.devices.find((d) => d.serialnumber === state.serialnumber)
            setSelectedCheckboxes((prev) =>
              selectedDevice && !prev.some((d) => d.serialnumber === selectedDevice.serialnumber)
                ? [selectedDevice, ...prev]
                : prev,
            )

            const filteredExpiringDevices = expiringDevices.filter(
              (device) => state && device.serialnumber !== state.serialnumber,
            )
            const filteredExpiredDevices = expiredDevices.filter(
              (device) => state && device.serialnumber !== state.serialnumber,
            )

            const combinedDevices = selectedDevice
              ? [selectedDevice, ...filteredExpiredDevices, ...filteredExpiringDevices]
              : [...filteredExpiredDevices, ...filteredExpiringDevices]
            setSelectedDevices(combinedDevices)

            if (combinedDevices.length === 0) {
              setSelectedDevices([...expiredDevices, ...expiringDevices])
            }
            setExpiringDevices(filteredExpiringDevices.length > 0 ? filteredExpiringDevices : expiringDevices)
            setExpiredDevices(filteredExpiredDevices.length > 0 ? filteredExpiredDevices : expiredDevices)
          }
          setLoading(false)
        }
      })
  }

  // const getExpiredDevices = async () => {
  //   setLoading(true)
  //   const response = await getPartnerExpiredStatistics(partner?.uuid, {
  //     partner_id: partner?.uuid,
  //     search_string: searchText,
  //     per_page: config.itemsPerPage,
  //     page: 0,
  //     months: 2,
  //   })
  //   if (response.response.ok) {
  //     const expiredDevices = response.data.devices
  //     setExpiredDevices(expiredDevices)
  //   }
  //   setLoading(false)
  // }

  const handleDurationChange = (item: number) => {
    setDuration(item)
    // const costWithoutVat = selectedCheckboxes.length * year * costPerYear
    // setTotalCostWithoutVAT(costWithoutVat)
    // const taxedValue = costWithoutVat * VAT_RATE
    // setTotalVAT(taxedValue)
    // setTotalCostWithVAT(costWithoutVat + taxedValue)
  }

  const handleRequestAddTrackers = async () => {
    setShowModal(false)
    setLoading(true)
    const deviceIds = selectedCheckboxes.map((ch) => ch.serialnumber).join("; ")
    if (!user || !partner) return
    const mailContent = `
      This is a generated message from the fleet portal: http://dashboard.iot-venture.com.\n
      "${partner?.name}" (${partner?.uuid}) (${userEmail}) has requested to extend the service of the following trackers:
      ${deviceIds}. Please contact the client shortly to discuss the pricing options for this service extension given below.\n
      Billing info:
      User ID: ${userId}
      Email: ${userEmail}
      Firstname: ${user.firstname}
      Lastname: ${user.lastname}
      Address: ${user.address}
      Selected service period: ${duration} months\n\nPlease follow the link, create a group and copy the serial numbers into the group:\nhttps://itsmybike.cloud/groups/new`

    const response = await sendEmailViaMailgun({
      from: "fleetportal@itsmybike.com",
      subject: "Tracker Service Extension Request",
      text: mailContent,
    })
    if (response.message) {
      setLoading(false)
      setShowSuccess(true)
      setSelectedCheckboxes([])
      setDuration(undefined)
      setSelectedDevices([])
      multiSelectRef.current && multiSelectRef.current.resetSelection()
      window.history.replaceState({}, "")
    }
  }

  useEffect(() => {
    if (duration) {
      handleDurationChange(duration)
    }
  }, [selectedCheckboxes])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const handleCheckbox = (device: SLIM_DEVICE_TYPE) => {
    setSelectedCheckboxes((prev) =>
      prev.some((ch) => ch.serialnumber === device.serialnumber)
        ? prev.filter((ch) => ch.serialnumber !== device.serialnumber)
        : [...prev, device],
    )
  }

  const monthYear = (item: number) => {
    return t(item == 12 ? "book_service.month_year" : "book_service.month_years", {
      month_value: item,
      year_value: item / 12,
    })
  }

  const handleSelectionChange = (selectedIds: number[]) => {
    const hasExpired = selectedIds.includes(1)
    const hasExpiring = selectedIds.includes(2)
    if (hasExpired && hasExpiring) {
      if (expiredDevices.length > 0 || expiringDevices.length > 0) {
        setSelectedDevices([...expiredDevices, ...expiringDevices])
        setSelectedCheckboxes([...expiredDevices, ...expiringDevices])
      }
    } else if (hasExpired) {
      setSelectedDevices(expiredDevices)
      setSelectedCheckboxes(expiredDevices)
    } else if (hasExpiring) {
      setSelectedDevices(expiringDevices)
      setSelectedCheckboxes(expiringDevices)
    } else {
      setSelectedCheckboxes([])
      if (expiredDevices.length > 0 || expiringDevices.length > 0) {
        setSelectedDevices([...expiredDevices, ...expiringDevices])
      }
    }
  }

  return (
    <div>
      {showSuccess && (
        <Card style={styles.warningCard} className="mt-3 mb-4 p-2">
          {t("mail_toast")}
        </Card>
      )}

      <Form.Control
        type="search"
        id="search"
        autoComplete="off"
        placeholder={sidebarT("search_placeholder")}
        value={searchText}
        onChange={onSearchChange}
      />

      <MultiSelect
        options={[
          { id: 1, label: t("book_service.expired_trackers") },
          { id: 2, label: t("book_service.expiring_trackers") },
        ]}
        selectedId={optionId}
        onChange={handleSelectionChange}
      />
      <div style={styles.divBox}>
        {loading ? (
          <div className="d-flex justify-content-center mt-4">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <ListGroup className="mt-2" style={styles.listGroup}>
            {selectedDevices.length !== 0 ? (
              <>
                {selectedDevices.map((result) => (
                  <ListGroup.Item key={result.serialnumber} onClick={() => handleCheckbox(result)}>
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        checked={selectedCheckboxes.some((ch) => ch.serialnumber === result.serialnumber)}
                        onChange={() => {}}
                      />
                      <label className="ms-2">
                        {result.serialnumber} - {result.name}
                      </label>
                    </div>
                  </ListGroup.Item>
                ))}
              </>
            ) : (
              <label>{t("book_service.no_trackers")}</label>
            )}
          </ListGroup>
        )}
      </div>

      <div className="mb-10">
        <DropdownButton
          className="mt-2"
          id="tariff-picker"
          title={duration ? monthYear(duration) : `${t("book_service.select_tariff")}`}>
          {tariff.map((item, i) => (
            <Dropdown.Item key={item} eventKey={i} onClick={() => handleDurationChange(item)}>
              {monthYear(item)}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>

      {/* <div style={styles.costBox}>
         <label>
          {t("book_service.cost_without_vat")}: {totalCostWithoutVAT.toFixed(2)} €
        </label>
        <label>
          {t("book_service.vat")} (19%): {totalVAT.toFixed(2)} €
        </label>
        <label>
          {t("book_service.total_cost")}: {totalCostWithVAT.toFixed(2)} €
        </label> 
      </div> */}

      <Button
        className="btn btn-primary-outline mt-4 mb-1"
        onClick={() => setShowModal(true)}
        disabled={selectedCheckboxes.length === 0}>
        {t("book_service.extend_service")}
      </Button>

      <ModalView
        title={t("book_service.extend_service")}
        buttonLoader={false}
        buttonTitle={t("request")}
        show={showModal}
        onHideModal={() => setShowModal(false)}
        onButtonClick={handleRequestAddTrackers}>
        <label>{t("book_service.request_extension_alert_refined")}</label>
      </ModalView>
    </div>
  )
}
