import { deletePartnerUuidFleetsUuid, postPartnerUuidFleets } from "@src/api"
import { InputField, ModalView, Page } from "@src/components"
import { colors } from "@src/constants"
import { colorTagStyle } from "@src/device"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import React, { useEffect, useState } from "react"
import { Button, Col, ListGroup, ListGroupItem, Spinner } from "react-bootstrap"
import { TrashFill } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"
import { FLEETS } from "./types"
import { useFleets } from "./useFleets"

export const FleetsScreen: React.FC = () => {
  const { partner } = usePartner()
  const navigate = useNavigate()
  const { t } = useLocalization("settings.fleets")
  const { t: sidebarT } = useLocalization("sidebar")
  const { fleets, updateFleets } = useFleets()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [fleetName, setFleetName] = useState<string>("")
  const [buttonLoader, setButtonLoader] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [selectedFleet, setSelectedFleet] = useState<FLEETS>()
  const [error, setError] = useState<boolean>(false)

  const onInputPersist = () => (text: string) => {
    setFleetName(text)
  }

  useEffect(() => {
    updateFleets()
  }, [])

  const createFleet = () => {
    if (fleetName.trim() !== "") {
      setError(false)
      setButtonLoader(true)
      if (partner) {
        postPartnerUuidFleets(partner.uuid, {
          fleet: {
            name: fleetName,
          },
        }).then(({ response, data }) => {
          setButtonLoader(false)
          setShowModal(false)
          if (response.ok && data) {
            updateFleets()
            navigate(`/${partner.uuid}/settings/fleets/${data.fleet.uuid}`)
          }
        })
      } else {
        setButtonLoader(false)
        setError(true)
      }
    } else {
      setError(true)
    }
  }

  const onClickDelete = (fleet: FLEETS) => {
    setDeleteLoader(true)
    setShowDeleteModal(false)
    if (partner)
      deletePartnerUuidFleetsUuid(partner.uuid, fleet.uuid).then(({ response }) => {
        if (response.ok) {
          setDeleteLoader(false)
          updateFleets()
        }
      })
  }

  const renderFleetsCard = (fleet: FLEETS) => {
    return (
      <ListGroupItem>
        <div className="d-flex align-items-center pointer">
          <Col
            onClick={() => {
              if (partner) navigate(`/${partner.uuid}/settings/fleets/${fleet.uuid}`)
            }}>
            <b>{fleet.name.charAt(0).toUpperCase() + fleet.name.slice(1)}</b>
          </Col>
          <b className="small me-3">
            ({fleet.devices_count} {sidebarT("devices")})
          </b>
          <Button
            disabled={selectedFleet && selectedFleet.uuid == fleet.uuid && deleteLoader}
            className="align-self-end"
            variant="danger"
            onClick={() => {
              setSelectedFleet(fleet)
              setShowDeleteModal(true)
            }}>
            {selectedFleet && selectedFleet.uuid == fleet.uuid && deleteLoader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <TrashFill color={colors.white} />
            )}
          </Button>
        </div>
      </ListGroupItem>
    )
  }

  return (
    <Page isSettings showHeader headerValue={sidebarT("fleets")}>
      <ListGroup>
        <ListGroup.Item
          action
          onClick={() => {
            setError(false)
            setShowModal(true)
          }}>
          <b>{t("newFleet")}</b>
        </ListGroup.Item>
        <>
          {fleets.length == 0 ? (
            <ListGroup.Item>{t("no_fleet")}</ListGroup.Item>
          ) : (
            <>{fleets.map((fleet) => renderFleetsCard(fleet))}</>
          )}
        </>
      </ListGroup>
      <ModalView
        title={t("createFleet")}
        buttonTitle={t("create")}
        show={showModal}
        buttonLoader={buttonLoader}
        onHideModal={() => setShowModal(false)}
        onButtonClick={createFleet}>
        <Col xs={12} md={6} lg={6}>
          <InputField
            label={t("fleetName")}
            value={""}
            onPersist={onInputPersist()}
            onChangeText={() => setError(false)}
            loading={false}
          />
          {error && (
            <label className="small d-flex mb-2" style={colorTagStyle(colors.red)}>
              {t("validNewFleetError")}
            </label>
          )}
        </Col>
      </ModalView>
      <ModalView
        title={t("alert")}
        buttonTitle={t("delete")}
        show={showDeleteModal}
        buttonLoader={deleteLoader}
        onHideModal={() => setShowDeleteModal(false)}
        onButtonClick={() => selectedFleet && onClickDelete(selectedFleet)}>
        <label>{t("areYouSure")}</label>
      </ModalView>
    </Page>
  )
}
