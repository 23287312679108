import { localization } from "@src/config"
import { TCAN_DATA } from "@src/devices/types"
import { usePersistedState } from "@src/utils"
import React, { createContext, useContext, useEffect } from "react"
import { initTranslation, validateLanguage } from "./helpers"
import i18n, { detectBrowserLocales } from "./i18n"

type LOCALIZATION_CONTEXT = {
  language: string
  region: string
  formatDate: (date: Date) => string
  formatTime: (date: Date) => string
  formatDateTime: (date: Date) => string
  changeLanguage: (lang: string) => void
  durationFromDate: (date: string) => string
  formatOdometer: (data: Pick<TCAN_DATA, "odometer" | "odometer_unit">) => string
}

const DEFAULT_LOCALIZATION_CONTEXT = {
  language: localization.defaultLang,
  region: localization.defaultRegion,
  formatDate: () => "",
  formatTime: () => "",
  formatDateTime: () => "",
  changeLanguage: () => {},
  durationFromDate: () => "",
  formatOdometer: () => "",
}
const [SYSTEM_LANGUAGE, SYSTEM_REGION] = detectBrowserLocales()

export const LocalizationContext = createContext<LOCALIZATION_CONTEXT>(DEFAULT_LOCALIZATION_CONTEXT)

export const LocalizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const t = initTranslation("map")
  const [language, setLanguage, languageFinished] = usePersistedState<string>("@language", SYSTEM_LANGUAGE)

  useEffect(() => {
    changeLanguage(language)
  }, [languageFinished])

  const changeLanguage = (lang: string) => {
    const newLang = validateLanguage(lang)
    i18n.locale = newLang
    setLanguage(newLang)
  }

  const format = (date: Date, pos: number): string => {
    if (SYSTEM_REGION) {
      const format = localization.localizationFormat[SYSTEM_REGION][pos]
      const parts = Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: Intl.DateTimeFormat(navigator.language).resolvedOptions().timeZone, // Detect browser's timezone
        timeZoneName: "short",
      }).formatToParts(date)
      const dateParts: { [key: string]: string } = {}
      parts.forEach((part) => {
        if (part.type !== "literal") {
          dateParts[part.type] = part.value
        }
      })
      // convert it to according to the format
      const formattedDate = format
        .replace("DD", dateParts.day ?? "00")
        .replace("MM", dateParts.month ?? "00")
        .replace("YYYY", dateParts.year ?? "0000")
        .replace("HH", dateParts.hour ?? "00")
        .replace("mm", dateParts.minute ?? "00")

      return formattedDate
    }
    return ""
  }

  const formatDate = (date: Date) => format(date, 0)
  const formatTime = (date: Date) => format(date, 1)
  const formatDateTime = (date: Date) => format(date, 2)

  const durationFromDate = (inputDate: string | Date): string => {
    const date = typeof inputDate === "string" ? new Date(inputDate) : inputDate
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date provided")
    }
    const now = new Date()
    const msDiff = now.getTime() - date.getTime()
    const seconds = Math.floor(msDiff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const months = Math.floor(days / 30)
    const years = Math.floor(days / 365)

    let quantity: number
    let unit: Intl.RelativeTimeFormatUnit

    if (years > 0) {
      quantity = years
      unit = "year"
    } else if (months > 0) {
      quantity = months
      unit = "month"
    } else if (days > 0) {
      quantity = days
      unit = "day"
    } else if (hours > 0) {
      quantity = hours
      unit = "hour"
    } else if (minutes > 0) {
      quantity = minutes
      unit = "minute"
    } else {
      quantity = seconds
      unit = "second"
    }

    const translationKey = `${unit}.${quantity === 1 ? "one" : "other"}`
    const translatedUnit = t(translationKey as keyof typeof t)
    return `${quantity} ${translatedUnit}`
  }

  const formatOdometer = ({ odometer, odometer_unit }: Pick<TCAN_DATA, "odometer" | "odometer_unit">) =>
    `${odometer ? (odometer_unit === "yd" ? odometer / 1760 : odometer / 1000) : null} ${odometer_unit === "yd" ? "mi" : "km"}`

  const context = {
    language: language || SYSTEM_LANGUAGE,
    region: SYSTEM_REGION,
    formatDate,
    formatTime,
    formatDateTime,
    changeLanguage,
    durationFromDate,
    formatOdometer,
  }

  return <LocalizationContext.Provider value={context}>{children}</LocalizationContext.Provider>
}

export const useLocalization = <T extends Parameters<typeof initTranslation>[0]>(objPath?: T) => ({
  ...useContext(LocalizationContext),
  t: objPath ? initTranslation(objPath) : () => "",
})
