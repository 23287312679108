import { StyleSheet } from "@src/types"
import React, { useEffect, useState } from "react"
import { Button, Row, Spinner } from "react-bootstrap"
import { Check } from "react-bootstrap-icons"

type ColorPickerProps = {
  color: string
  loading?: boolean
  onChange: (color: string) => void
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange, loading }) => {
  const [colorValue, setColorValue] = useState<string>(color)
  const [preColorValue, setPreColorValue] = useState<string>(color)

  const styles: StyleSheet = {
    tickButton: { display: "flex", width: "35px", height: "35px", alignItems: "center", justifyContent: "center" },
    inputStyle: {
      width: "35px",
      height: "35px",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      overflow: "hidden",
      appearance: "none",
      padding: "0",
    },
    colorContainer: { display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px", marginTop: "10px" },
  }

  useEffect(() => {
    if (color != preColorValue) {
      setColorValue(color)
      setPreColorValue(color)
    }
  }, [color])

  return (
    <div>
      <Row>
        <div style={styles.colorContainer}>
          <input
            id="colorPicker"
            type="color"
            className="ms-1 me-1"
            value={colorValue}
            onClick={() => {
              setPreColorValue(colorValue)
            }}
            onChange={(e) => setColorValue(e.target.value)}
            style={styles.inputStyle}
          />
          <b>{colorValue.toUpperCase()}</b>
          {loading && <Spinner className="ms-2" animation="border" size="sm" />}
          {preColorValue != colorValue && (
            <Button
              size="sm"
              onClick={() => {
                setPreColorValue(colorValue)
                onChange(colorValue)
              }}
              style={styles.tickButton}
              variant="outline-dark">
              <Check size={30} />
            </Button>
          )}
        </div>
      </Row>
    </div>
  )
}
