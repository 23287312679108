import { getInvitationAcceptToken, getInvitationDeclineToken } from "@src/api"
import { useAuthentication } from "@src/auth"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

type INVITE_CONTEXT = {
  inviteToken: string
  acceptToken: () => Promise<any>
  declineToken: () => Promise<any>
  updateInviteToken: (tokenId?: string) => void
}

const DEFAULT_INVITE_CONTEXT = {
  inviteToken: "",
  acceptToken: async () => {},
  declineToken: async () => {},
  updateInviteToken: () => {},
}

export const InviteContext = createContext<INVITE_CONTEXT>(DEFAULT_INVITE_CONTEXT)

export const InviteProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, updateInvite } = useAuthentication()
  const [inviteToken, setInviteToken] = useState<string>("")
  const navigate = useNavigate()
  // get the path
  const path = window.location.pathname

  // check the path contains invite or not

  useEffect(() => {
    if (!isAuthenticated && path.includes("/invite")) {
      navigate("/login")
      updateInvite(true)
    }
  }, [isAuthenticated, navigate, updateInvite])

  const updateInviteToken = (tokenId: string = "") => {
    setInviteToken(tokenId)
  }

  // const navigateTologin = () => {
  //   if (!isAuthenticated) {
  //     navigate("/login")
  //     updateInvite(true)
  //   } else {
  //     //TODO: status 422 :- token not valid or already used, user not found
  //     //TODO:- Once register PR is merged, below line will be uncommented.
  //     //navigate("/register")
  //   }
  // }

  const acceptToken = async () => {
    const { response, data } = await getInvitationAcceptToken(inviteToken)
    if (response.ok) {
      updateInvite(false)
    }
    return { response, data }
  }

  const declineToken = async () => {
    const { response, data } = await getInvitationDeclineToken(inviteToken)
    if (response.ok) {
      updateInvite(false)
    }
    return { response, data }
  }

  const context = {
    inviteToken,
    acceptToken,
    declineToken,
    updateInviteToken,
  }

  return <InviteContext.Provider value={context}>{children}</InviteContext.Provider>
}

export const useInvite = () => useContext(InviteContext)
