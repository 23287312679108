import { getDeviceSerialnumber, putDeviceSerialnumberFound, putDeviceSerialnumberStolen } from "@src/api"
import { DeviceSearch, Page } from "@src/components"
import { spacer } from "@src/constants"
import { DEVICE_TYPE } from "@src/devices/types"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import React, { useEffect, useState } from "react"
import { Button, Col, Form, Image, InputGroup, Modal, Row, Spinner } from "react-bootstrap"
import { FileEarmarkArrowDown, X } from "react-bootstrap-icons"
import { useParams } from "react-router-dom"

type THEFT_TYPE = {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  specialFeature: string
  description: string
  checkbox: boolean
}

const DEFAULT_THEFT: THEFT_TYPE = {
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  specialFeature: "",
  description: "",
  checkbox: false,
}

const convertToTimestamp = (date: string, time: string): number => new Date(`${date} ${time}`).getTime()

const styles = {
  displayFlex: { display: "flex" },
  headerView: { display: "flex", marginBottom: spacer[2] },
  buttonView: { marginLeft: spacer[2] },
}

export const TheftReportScreen: React.FC = () => {
  const { serialnumber: serialnumberParam = "" } = useParams()
  const { t } = useLocalization("theft")
  const { t: sidebarT } = useLocalization("sidebar")
  const { partner } = usePartner()
  const [serialnumber, setSerialnumber] = useState<string>(serialnumberParam)
  const [device, setDevice] = useState<DEVICE_TYPE>()
  const [serialnumberInput, setSerialnumberInput] = useState<string>(serialnumberParam)
  const [theft, setTheft] = useState<THEFT_TYPE>(DEFAULT_THEFT)
  const [modal, setModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const today = new Date().toISOString().split("T")[0]
  const isStartBeforeEnd =
    convertToTimestamp(theft.startDate, theft.startTime) < convertToTimestamp(theft.endDate, theft.endTime)
  const theftStartAndEndFilled = theft.startDate && theft.startTime && theft.endDate && theft.endTime
  const reportTheftAllowed = isStartBeforeEnd && theftStartAndEndFilled && theft.checkbox

  const fetchDevice = () => {
    if (partner?.uuid) {
      return getDeviceSerialnumber(partner?.uuid, serialnumber).then(({ response, data }) => {
        if (response.ok) setDevice(data)
      })
    }
    return Promise.resolve()
  }

  useEffect(() => {
    fetchDevice()
  }, [partner, serialnumber])

  const onChange = (attribute: keyof THEFT_TYPE) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheft((prevTheft) => ({
      ...prevTheft,
      [attribute]: e.target.type === "checkbox" ? e.target.checked : e.target.value,
    }))
  }

  const closeModal = () => setModal(false)
  const showModal = () => setModal(true)
  const onReportTheft = () => {
    setLoading(true)
    partner &&
      putDeviceSerialnumberStolen(partner?.uuid, {
        device_serialnumber: serialnumber,
        circumstanceofcrime: theft.description,
        specialfeatures: theft.specialFeature,
        stolen_timestamp_from: convertToTimestamp(theft.startDate, theft.startTime).toString(),
        stolen_timestamp_to: convertToTimestamp(theft.endDate, theft.endTime).toString(),
      }).then(() => {
        fetchDevice().then(() => {
          closeModal()
          setLoading(false)
        })
      })
  }
  const onDeviceFound = () => {
    setLoading(true)
    partner &&
      putDeviceSerialnumberFound(partner?.uuid, serialnumber).then(() => {
        fetchDevice().then(() => {
          setLoading(false)
        })
      })
  }

  return (
    <Page showHeader headerValue={sidebarT("theft_report")}>
      <Form.Label>{t("stolen_device")}</Form.Label>
      <div style={styles.headerView}>
        <Form.Control
          type="search"
          id="search"
          placeholder={t("stolen_device_input")}
          value={serialnumber || serialnumberInput}
          disabled={!!serialnumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSerialnumberInput(e.target.value.toString())
          }}
        />
        {serialnumber && (
          <Button
            variant="light"
            style={styles.buttonView}
            onClick={() => {
              setDevice(undefined)
              setSerialnumber("")
            }}>
            <X className="bi" />
          </Button>
        )}
      </div>
      {!serialnumber && serialnumberInput.length > 0 && (
        <DeviceSearch
          searchText={serialnumberInput}
          onClick={(device) => {
            setSerialnumber(device.serialnumber)
          }}
        />
      )}
      {serialnumber && (
        <>
          <div className="mb-3">
            {device?.icon && <Image rounded src={device?.icon} width={70} className="me-2" />}
            <Form.Label>{device?.name}</Form.Label>
          </div>
          {!device?.is_stolen ? (
            <>
              <Form.Label>{t("time_period")}</Form.Label>
              <Row className="mb-3">
                <Col>
                  <Form.Label>{t("start")}:</Form.Label>
                  <div style={styles.displayFlex}>
                    <Form.Control
                      type="date"
                      value={theft.startDate}
                      onChange={onChange("startDate")}
                      className="me-1"
                      max={theft.endDate || today}
                    />
                    <Form.Control type="time" value={theft.startTime} onChange={onChange("startTime")} />
                  </div>
                </Col>
                <Col>
                  <Form.Label>{t("end")}:</Form.Label>
                  <div style={styles.displayFlex}>
                    <Form.Control
                      type="date"
                      value={theft.endDate}
                      onChange={onChange("endDate")}
                      className="me-1"
                      min={theft.startDate}
                      max={today}
                    />
                    <Form.Control type="time" value={theft.endTime} onChange={onChange("endTime")} />
                  </div>
                </Col>
              </Row>
              {!isStartBeforeEnd && theftStartAndEndFilled && <p className="text-danger small">{t("range_error")}</p>}
              <Form.Label>{t("special_features")}</Form.Label>
              <Form.Control
                as="textarea"
                className="mb-3"
                value={theft.specialFeature}
                onChange={onChange("specialFeature")}
              />
              <Form.Label>{t("description")}</Form.Label>
              <Form.Control
                as="textarea"
                className="mb-3"
                value={theft.description}
                onChange={onChange("description")}
              />
              <Form.Text muted>{t("explanation")}</Form.Text>
              <InputGroup className="mt-2 mb-3">
                <Form.Check label={t("chackbox_label")} checked={theft.checkbox} onChange={onChange("checkbox")} />
              </InputGroup>
              <Button onClick={showModal} disabled={!reportTheftAllowed}>
                {t("report_theft")}
              </Button>
              <Modal show={modal} onHide={closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>{t("report_theft")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("warning")}</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={closeModal}>
                    {t("close")}
                  </Button>
                  <Button variant="primary" onClick={onReportTheft}>
                    {loading ? <Spinner animation="border" size="sm" variant="light" /> : t("report_theft_now")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          ) : (
            <>
              <Form.Label>{t("report_successful")}</Form.Label>
              <Form.Label>{t("report_police")}</Form.Label>
              <Button variant="light" href={device?.theftreport_path as string} target="_blank">
                <FileEarmarkArrowDown className="bi me-2" />
                {t("online_report")}
              </Button>
              <Button variant="success" onClick={onDeviceFound}>
                {loading ? <Spinner animation="border" size="sm" variant="light" /> : t("device_found")}
              </Button>
            </>
          )}
        </>
      )}
    </Page>
  )
}
