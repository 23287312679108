import { postUser } from "@src/api/requests/postUser"
import { InputField } from "@src/components"
import { colors, config } from "@src/constants"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { useLocalization } from "@src/localization"
import { SYSTEM_OF_MEASUREMENT, USER } from "@src/user"
import { validation } from "@src/utils/validation"
import React, { useEffect, useState } from "react"
import { Alert, Button, Col, Container, Dropdown, DropdownButton, Row, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

const COLUMN = { marginTop: "10rem", borderRadius: 10 }

const styles = {
  divider: {
    backgroundColor: colors.gray,
    height: 1,
    width: "100%",
  },
}

const defaultUser = {
  firstname: "",
  lastname: "",
  password: "",
  email: "",
  address: "",
  langcode: "",
  system_of_measurement: "metric",
} satisfies Omit<NonNullable<USER>, "id">

export const RegisterUser: React.FC = () => {
  const { t } = useLocalization("register_user")
  const { t: userformT } = useLocalization("user_form")
  const loginLocalization = useLocalization("login_screen")
  const settingsLocalization = useLocalization("settings")
  const { language } = useLocalization()
  const [newUser, setNewUser] = useState<Omit<NonNullable<USER>, "id">>(defaultUser)
  const [loading, setLoading] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [systemOfMeasurement, setSystemOfMeasurement] = useState<SYSTEM_OF_MEASUREMENT>()
  const units: SYSTEM_OF_MEASUREMENT[] = ["imperial", "metric"]
  const navigate = useNavigate()

  useEffect(() => {
    if (newUser) {
      const langData = { ...newUser, ["langcode"]: language }
      setNewUser(langData)
    }
  }, [language])

  const onInputPersist = (field: keyof typeof defaultUser) => (text: string) => {
    setShowAlert(false)
    const newUserData = { ...newUser, [field]: text }
    setNewUser(newUserData)
  }

  const onSubmit = () => {
    setLoading(true)
    if (newUser) {
      postUser({ user: newUser }).then(({ response }) => {
        setLoading(false)
        if (response.ok) {
          setShowAlert(true)
          setNewUser(defaultUser)
        } else {
          setShowAlert(true)
        }
        setTimeout(() => {
          setShowAlert(false)
        }, config.alertSuccessTimeOut)
      })
    }
  }

  const enabled =
    !!(newUser && newUser.email && !validation.regex.email.test(newUser && newUser.email)) ||
    !!(newUser && newUser.password && !validation.regex.password.test(newUser && newUser.password))

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md="10" lg="8" className="shadow-lg bg-body p-4" style={COLUMN}>
          <h4 className="large d-flex">{loginLocalization.t("register_user")}</h4>
          <div className="mt-2 mb-4" style={styles.divider}></div>
          <InputField
            name={"field-1"}
            label={userformT("firstname")}
            value={newUser && newUser.firstname ? newUser.firstname : ""}
            onChangeText={onInputPersist("firstname")}
            onPersist={onInputPersist("firstname")}
            validate={newUser && newUser.firstname === ""}
            errorMsg={userformT("validation.firstnameValid")}
            loading={false}
            isFocused={true}
          />
          <InputField
            name={"field-2"}
            label={userformT("lastname")}
            value={newUser && newUser.lastname ? newUser.lastname : ""}
            onPersist={onInputPersist("lastname")}
            onChangeText={onInputPersist("lastname")}
            loading={false}
            validate={newUser && newUser.lastname === ""}
            errorMsg={userformT("validation.lastnameValid")}
          />

          <InputField
            name={"field-3"}
            label={userformT("email")}
            value={newUser && newUser.email ? newUser.email : ""}
            onPersist={onInputPersist("email")}
            onChangeText={onInputPersist("email")}
            loading={false}
            validate={!validation.regex.email.test(newUser && newUser.email ? newUser.email : "")}
            errorMsg={userformT("validation.emailValid")}
            isMandatory={true}
          />

          <InputField
            name={"field-4"}
            label={userformT("password")}
            value={newUser && newUser.password ? newUser.password : ""}
            onPersist={onInputPersist("password")}
            validate={!validation.regex.password.test(newUser && newUser.password ? newUser.password : "")}
            onChangeText={onInputPersist("password")}
            errorMsg={userformT("validation.passwordValid")}
            loading={false}
            isMandatory={true}
          />

          <InputField
            name={"field-5"}
            label={userformT("address")}
            value={newUser && newUser.address ? newUser.address : ""}
            onPersist={onInputPersist("address")}
            validate={newUser && newUser.address == ""}
            loading={false}
          />

          <>
            <b className="small">{settingsLocalization.t("user.unit")}:</b>
            <div className="d-flex flex-row align-items-center">
              <DropdownButton
                className="mt-2 me-2"
                id="time-interval-picker"
                title={
                  systemOfMeasurement
                    ? settingsLocalization.t(`user.${systemOfMeasurement}`)
                    : settingsLocalization.t("user.select_unit")
                }>
                {units.map((item, i) => (
                  <Dropdown.Item
                    key={item}
                    eventKey={i}
                    onClick={() => {
                      // setLoading(true)
                      if (newUser) {
                        const newUserData = { ...newUser, system_of_measurement: item }
                        setNewUser(newUserData)
                      }
                      setSystemOfMeasurement(item)
                    }}>
                    {settingsLocalization.t(`user.${item}`)}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
              {loading && <Spinner style={sortArrowStyles.loader} animation="border" className="mt-2" />}
            </div>
          </>
          <Button type="submit" className="text-white mt-3" disabled={enabled} onClick={onSubmit}>
            {loading ? <Spinner animation="border" size="sm" variant="light" /> : userformT("submit").toUpperCase()}
          </Button>
          <div className="mt-2">
            <label> {t("already_account")} </label>
            <a className="pointer" onClick={() => navigate("/login")}>
              {t("clickHere")}
            </a>
          </div>

          {showAlert && (
            <Alert className="mt-3" variant={!newUser ? "success" : "danger"}>
              {!newUser ? userformT("validation.user_created_success") : userformT("validation.email_already_exist")}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  )
}
