import { getFleetsUuidSettings, NOTIFICATION_SETINGS, postFleetsUuidSettings } from "@src/api"
import { sortArrowStyles } from "@src/device/devicedetail/tabs/components"
import { useLocalization } from "@src/localization"
import { usePartner } from "@src/partner"
import React, { useEffect, useState } from "react"
import { Form, ListGroup, ListGroupItem, Spinner } from "react-bootstrap"
import { useFleets } from "../useFleets"

const divWidth = { width: "16px" }

export const FleetNotificationScreen: React.FC = () => {
  const { t } = useLocalization("settings.fleets.notifications")
  const { partner } = usePartner()
  const { fleet } = useFleets()
  const [loading, setLoading] = useState<boolean>(false)
  const [settingsData, setSettingsData] = useState<NOTIFICATION_SETINGS>()
  const [selectedKey, setSelectedKey] = useState<any>()
  const settings: { key: keyof NOTIFICATION_SETINGS; value: string; description: string }[] = [
    {
      key: "battery_low",
      value: t("battery_low"),
      description: t("battery_low_desc"),
    },
    {
      key: "battery_very_low",
      value: t("battery_very_low"),
      description: t("battery_very_low_desc"),
    },
    {
      key: "locked_distance",
      value: t("locked_distance"),
      description: t("locked_distance_desc"),
    },
    {
      key: "locked_movement",
      value: t("locked_movement"),
      description: t("locked_movement_desc"),
    },
    {
      key: "left_geofence",
      value: t("left_geofence"),
      description: t("left_geofence_desc"),
    },
    {
      key: "entered_geofence",
      value: t("entered_geofence"),
      description: t("entered_geofence_desc"),
    },
  ]

  useEffect(() => {
    setLoading(true)
    if (partner && fleet) getSettings()
  }, [partner, fleet])

  const getSettings = () => {
    if (partner && fleet)
      getFleetsUuidSettings(partner.uuid, fleet.uuid).then(({ response, data }) => {
        setLoading(false)
        if (response.ok) {
          setSettingsData(data)
        }
      })
  }

  const postSettings = (key: string) => {
    setSelectedKey(key)
    if (partner && fleet && settingsData)
      postFleetsUuidSettings(partner.uuid, fleet.uuid, {
        [key]: !settingsData[key as keyof NOTIFICATION_SETINGS],
      }).then(({ response }) => {
        setSelectedKey("")
        const postData = { ...settingsData, [key]: !settingsData[key as keyof NOTIFICATION_SETINGS] }
        setSettingsData(postData)
        if (response.ok) {
          getSettings()
        }
      })
  }

  return (
    <div>
      {loading ? (
        <div className="align-items-center d-flex justify-content-center m-5">
          <Spinner animation={"border"} />
        </div>
      ) : (
        <ListGroup className="pointer">
          {settings.map((item) => (
            <ListGroupItem key={item.key} action onClick={() => postSettings(item.key)}>
              <div className="d-flex align-items-center flex-row">
                <div style={divWidth}>
                  {selectedKey == item.key ? (
                    <Spinner animation="border" style={sortArrowStyles.loader} />
                  ) : (
                    <Form.Check
                      type="checkbox"
                      checked={settingsData && settingsData[item.key] === true}
                      onChange={() => postSettings(item.key)}
                    />
                  )}
                </div>
                <b className="ms-3">{item.value}</b>
              </div>
              <div className="d-flex flex-column mt-1">
                <label className="small pointer">{item.description}</label>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </div>
  )
}
