import { useLocalization } from "@src/localization"
import React, { useEffect } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useSearchParams } from "react-router-dom"
import { AddTrackers } from "./AddTrackers"
import { BookTrackerService } from "./BookTrackerService"
import { ManageTrackers } from "./ManageTrackers"
import { PartnerAccessScreen } from "./PartnerAccessScreen"
import { PartnerDetail } from "./PartnerDetail"

const TABS = ["access_rights", "brand", "manage", "add", "book_tracker", "invitations"]

export const TABLE_COLUMNS = ["users", "email", "roles"]

export const PartnerTabs: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = searchParams.get("tab")
  const { t } = useLocalization("settings.partner")

  useEffect(() => {
    if (tab === null || !TABS.includes(tab)) setSearchParams({ tab: "access_rights" }, { replace: true })
  }, [setSearchParams, tab])

  return (
    <Tabs
      activeKey={tab ?? TABS[0]} // Provide a fallback value
      onSelect={(key) => {
        if (key) {
          setSearchParams({ tab: key })
        }
      }}
      className="mb-3">
      <Tab eventKey={TABS[0]} title={t("tabs.access_rights")}>
        <PartnerAccessScreen />
      </Tab>
      <Tab eventKey={TABS[1]} title={t("tabs.brand")}>
        <PartnerDetail />
      </Tab>
      {/* the below code is added for a temporary flow until the backend is ready  */}
      <Tab eventKey={TABS[2]} title={t("alert")}>
        <ManageTrackers />
      </Tab>
      <Tab eventKey={TABS[3]} title={t("add_tracker")}>
        <AddTrackers />
      </Tab>
      <Tab eventKey={TABS[4]} title={t("book_service.service_extension")}>
        <BookTrackerService />
      </Tab>
    </Tabs>
  )
}
