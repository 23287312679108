import { env } from "@src/utils"

export const api = {
  baseUrl:
    import.meta.env.MODE === "development"
      ? "/dev-api"
      : env === "production"
        ? "https://itsmybike.cloud/api/web/v1"
        : "https://itsmybike-staging.cloud/api/web/v1",
  iLockItUrl: "https://ilockit-key-hub-a0d3b611429d.herokuapp.com",
} as const
