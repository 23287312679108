import { useAuthentication } from "@src/auth"
import { colors } from "@src/constants"
import React from "react"
import { Nav, Spinner } from "react-bootstrap"
import * as Icon from "react-bootstrap-icons"
import { Link } from "react-router-dom"

export const LoginLogoutButton: React.FC = () => {
  const { isAuthenticated, loading, logout } = useAuthentication()
  if (!isAuthenticated) return null
  return (
    <Nav.Link as={Link} className="logoutbtn" onClick={logout} to="" eventKey="">
      {loading ? (
        <Spinner animation="border" size="sm" variant="light" />
      ) : (
        <Icon.BoxArrowRight color={colors.white} size={18} fontWeight="bold" />
      )}
    </Nav.Link>
  )
}
